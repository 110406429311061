import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axiosInstance from "./axiosInstance";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DriverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

const DriverCard = styled.div`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #ffffff;
`;

const InputField = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#ccc")};

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const DatePickerWrapper = styled.div`
  margin-bottom: 10px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${(props) => (props.invalid ? "red" : "#ccc")};
    }
  }
`;

const Button = styled.button`
  background: ${(props) => props.color || "#007bff"};
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.hover || "#0056b3"};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DriverInfo = ({
  drivers,
  setDrivers,
  companyId,
  refreshResources,
  readOnly = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [postingDriverIndex, setPostingDriverIndex] = useState(null);

  const handleDriverChange = (index, field, value) => {
    setDrivers((prevDrivers) =>
      prevDrivers.map((driver, i) =>
        i === index ? { ...driver, [field]: value } : driver
      )
    );
  };

  const validateDriverFields = (driver) => {
    const requiredFields = [
      "fullName",
      "email",
      "phoneNumber",
      "licenseNumber",
      "licenseExpiryDate",
    ];

    const missingFields = requiredFields.filter((field) => !driver[field]);
    if (missingFields.length > 0) {
      enqueueSnackbar(`Missing fields: ${missingFields.join(", ")}`, {
        variant: "error",
      });
      return false;
    }
    if (new Date(driver.licenseExpiryDate) <= new Date()) {
      enqueueSnackbar("License expiry date must be in the future.", {
        variant: "error",
      });
      return false;
    }

    return true;
  };

  const handlePostDriver = async (driver, index) => {
  console.log("Attempting to post driver:", driver);
  console.log("Using companyId:", companyId);

  if (!validateDriverFields(driver)) return;

  setPostingDriverIndex(index);
  try {
    const response = await axiosInstance.post(
      `/company/${companyId}/add-driver`,
      driver
    );
    enqueueSnackbar(response.data.message, { variant: "success" });
    handleRemoveDriver(index); 
    await refreshResources(); 
  } catch (error) {
    console.error("Error posting driver:", error.response || error.message);
    enqueueSnackbar(error.response?.data?.error || "Failed to add driver.", {
      variant: "error",
    });
  } finally {
    setPostingDriverIndex(null);
  }
};


  const handleAddNewDriver = () => {
    setDrivers((prevDrivers) => [
      ...prevDrivers,
      {
        fullName: "",
        email: "",
        phoneNumber: "",
        licenseNumber: "",
        licenseExpiryDate: null,
      },
    ]);
  };

  const handleRemoveDriver = (index) => {
    setDrivers((prevDrivers) => prevDrivers.filter((_, i) => i !== index));
  };

  return (
    <DriverContainer>
      <h4>Driver Information</h4>
      {drivers.map((driver, index) => (
        <DriverCard key={index}>
          {["fullName", "email", "phoneNumber", "licenseNumber"].map(
            (field) => (
              <InputField
                key={field}
                placeholder={field.replace(/([A-Z])/g, " $1")}
                value={driver[field] || ""}
                onChange={(e) =>
                  handleDriverChange(index, field, e.target.value)
                }
                disabled={readOnly}
              />
            )
          )}
          <DatePickerWrapper>
            <DatePicker
              selected={
                driver.licenseExpiryDate
                  ? new Date(driver.licenseExpiryDate)
                  : null
              }
              onChange={(date) =>
                handleDriverChange(index, "licenseExpiryDate", date)
              }
              placeholderText="Select License Expiry Date"
              minDate={new Date()} 
              disabled={readOnly}
            />
          </DatePickerWrapper>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              color="#28a745"
              hover="#218838"
              onClick={() => handlePostDriver(driver, index)}
              disabled={readOnly || postingDriverIndex === index}
            >
              {postingDriverIndex === index ? "Posting..." : "Post to Server"}
            </Button>
            <Button
              color="#dc3545"
              hover="#c82333"
              onClick={() => handleRemoveDriver(index)}
              disabled={readOnly || postingDriverIndex === index}
            >
              Remove
            </Button>
          </div>
        </DriverCard>
      ))}
      <Button onClick={handleAddNewDriver} disabled={readOnly}>
        Add Driver
      </Button>
    </DriverContainer>
  );
};

DriverInfo.propTypes = {
  drivers: PropTypes.array.isRequired,
  setDrivers: PropTypes.func.isRequired,
  refreshResources: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default DriverInfo;
