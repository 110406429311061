import React, { useState, useEffect } from "react";
import {
  StyledModal,
  ModalContent,
  ReviewsContainer,
  ReviewBox,
  SubmitButton,
} from "./CompanyModal.styles";
import {
  Typography,
  IconButton,
  Box,
  Divider,
  TextField,
  Chip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEnvelope,
  faPhone,
  faTruck,
  faBox,
  faStar,
  faBuilding,
  faIdCard,
  faCalendar,
  faUsers,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { Button } from "react-bootstrap";

const CompanyModal = ({
  company,
  onClose,
  onSubmitReview,
  fetchReviews,
  reviewerCompanyId,
}) => {
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [loadingReviews, setLoadingReviews] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (company?._id) {
      setLoadingReviews(true);
      fetchReviews(company._id)
        .then(setReviews)
        .catch((error) => {
          console.error("Error fetching reviews:", error.message);
          enqueueSnackbar("Failed to load reviews.", { variant: "error" });
        })
        .finally(() => setLoadingReviews(false));
    }
  }, [company, fetchReviews, enqueueSnackbar]);

  const handleReviewSubmit = async () => {
    if (!rating || !review.trim()) {
      enqueueSnackbar("Please provide a valid rating and comment.", {
        variant: "warning",
      });
      return;
    }

    const reviewData = {
      companyId: company._id,
      reviewerCompanyId,
      rating,
      comment: review,
    };

    try {
      await onSubmitReview(reviewData);
      setRating(0);
      setReview("");
      enqueueSnackbar("Review submitted successfully!", { variant: "success" });
    } catch (error) {
      console.error("Error submitting review:", error.message);
      enqueueSnackbar("Failed to submit review.", { variant: "error" });
    }
  };

  const calculateAverageRating = () => {
    if (!reviews.length) return 0;
    const total = reviews.reduce((sum, review) => sum + review.rating, 0);
    return (total / reviews.length).toFixed(1);
  };

  const renderDetail = (icon, label, value) => (
    <Box display="flex" alignItems="center" mt={1}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: 8 }} />
      <Typography>
        {label}: {value || "N/A"}
      </Typography>
    </Box>
  );

  return (
    <StyledModal
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        padding: "16px",
      }}
    >
      <ModalContent
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%", lg: "50%" },
          maxHeight: { xs: "95vh", lg: "80vh" },
          overflowY: "auto",
          padding: { xs: "16px", sm: "24px" },
          backgroundColor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        {/* Header Section */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: "1rem", fontWeight: "bold", mb: 1 }}
          >
            {company.companyName}
          </Typography>
          <Chip
            icon={
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: company.isOnline ? "green" : "gray" }}
              />
            }
            label={company.isOnline ? "Online" : "Offline"}
            sx={{
              backgroundColor: company.isOnline ? "#4caf50" : "#b0bec5",
              color: "#fff",
              fontWeight: "bold",
            }}
          />
          <IconButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Contact Information */}
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ fontSize: ".9rem", fontWeight: "bold", mb: 1 }}
          >
            Contact Information
          </Typography>
          {renderDetail(faEnvelope, "Email", company.companyEmail)}
          {renderDetail(faPhone, "Phone", company.phoneNumber)}
          {renderDetail(faBuilding, "Address", company.companyAddress)}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Additional Details */}
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ fontSize: ".9rem", fontWeight: "bold", mb: 1 }}
          >
            Additional Details
          </Typography>
          {renderDetail(faIdCard, "DOT/MC Number", company.dotOrMcNumber)}
          {renderDetail(
            faCalendar,
            "Registered",
            company.registrationDate
              ? new Date(company.registrationDate).toLocaleDateString()
              : "N/A"
          )}
          {renderDetail(faUsers, "Employees", company.totalDriverCount)}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Company Stats */}
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            Company Stats
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={1}>
            {renderDetail(faTruck, "Trucks", company.trucks?.length)}
            {renderDetail(faBox, "Shipments", company.finishedShipmentsCount)}
            {renderDetail(faStar, "Rating", calculateAverageRating())}
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Reviews Section */}
        {loadingReviews ? (
          <Typography>Loading reviews...</Typography>
        ) : reviews.length > 0 ? (
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Reviews
            </Typography>
            <ReviewsContainer>
              {reviews.map((review, index) => (
                <ReviewBox key={index}>
                  <Typography variant="body2" fontWeight="bold">
                    {review.reviewerCompanyId?.companyName || "Anonymous"}
                  </Typography>
                  <Typography variant="body2">{review.comment}</Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    {Array.from({ length: review.rating }, (_, i) => (
                      <FontAwesomeIcon
                        key={i}
                        icon={faStar}
                        style={{ color: "gold", marginRight: 2 }}
                      />
                    ))}
                  </Box>
                </ReviewBox>
              ))}
            </ReviewsContainer>
          </Box>
        ) : (
          <Typography>No reviews available.</Typography>
        )}

        {/* Submit Review Section */}
        <Box mt={3}>
          <Typography variant="subtitle1" fontWeight="bold" mb={1}>
            Submit Your Review
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            {Array.from({ length: 5 }, (_, i) => (
              <FontAwesomeIcon
                key={i}
                icon={faStar}
                onClick={() => setRating(i + 1)}
                style={{
                  color: i < rating ? "gold" : "gray",
                  cursor: "pointer",
                  marginRight: 4,
                }}
              />
            ))}
          </Box>
          <TextField
            label="Your Review"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
          <Button onClick={handleReviewSubmit}>
            Submit Review
          </Button>
        </Box>

        <Box textAlign="center" mt={3}>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </ModalContent>
    </StyledModal>
  );
};

export default CompanyModal;
