import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ShipmentDetailsDialog from "./ShipmentDetailsDialog";
import ShipmentTable from "./ShipmentTable";
import { useAuth } from "./AuthContext/AuthContext";
import "./Viewshipment.css";
import { useSnackbar } from "notistack";

const ViewShipments = ({
  localResources = { companyId: "", allTrucks: [], allDrivers: [] },
}) => {
  const { fetchAllShipments, deleteShipment } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState({
    available: [],
    inTransit: [],
    completed: [],
  });
  const [selectedShipment, setSelectedShipment] = useState(null);

  const fetchShipments = useCallback(async () => {
    setLoading(true);
    try {
      const allShipments = await fetchAllShipments();
      const categorizedShipments = {
        available: allShipments.filter(
          (shipment) => shipment.status?.toLowerCase() === "available"
        ),
        inTransit: allShipments.filter(
          (shipment) => shipment.status?.toLowerCase() === "in transit"
        ),
        completed: allShipments.filter(
          (shipment) => shipment.status?.toLowerCase() === "completed"
        ),
      };
      setShipments(categorizedShipments);
    } catch (error) {
      console.error("Error fetching shipments:", error);
      enqueueSnackbar("Failed to fetch shipments.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [fetchAllShipments, enqueueSnackbar]);

  useEffect(() => {
    fetchShipments();
  }, [fetchShipments]);

  const handleShipmentActionComplete = () => {
    fetchShipments();
    setSelectedShipment(null);
  };

  const handleDeleteShipment = async (shipmentId) => {
    try {
      const response = await deleteShipment(shipmentId);
      if (response.success) {
        enqueueSnackbar(`Shipment ${shipmentId} deleted successfully.`, {
          variant: "success",
        });
        fetchShipments();
      } else {
        enqueueSnackbar(
          `Failed to delete shipment ${shipmentId}: ${response.error}`,
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error("Error deleting shipment:", error);
      enqueueSnackbar("Failed to delete shipment. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <div className="custom-container">
      <h1 className="custom-title">Your Shipments</h1>

      {loading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : shipments.available.length > 0 ? (
        <ShipmentTable
          shipments={shipments.available}
          onView={setSelectedShipment}
          onDelete={handleDeleteShipment}
        />
      ) : (
        <p className="no-shipments-text">No shipments available.</p>
      )}

      {selectedShipment && (
        <ShipmentDetailsDialog
          open={Boolean(selectedShipment)}
          shipment={selectedShipment}
          onPickupComplete={handleShipmentActionComplete}
          onBidComplete={(bidDetails) =>
            handleShipmentActionComplete(selectedShipment.id, bidDetails)
          }
          availableTrucks={localResources.allTrucks}
          availableDrivers={localResources.allDrivers}
          loadingAction={loading}
          onClose={() => setSelectedShipment(null)}
        />
      )}
    </div>
  );
};

ViewShipments.propTypes = {
  localResources: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    allTrucks: PropTypes.arrayOf(PropTypes.object).isRequired,
    allDrivers: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};

export default ViewShipments;
