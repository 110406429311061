import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
  useContext,useState
} from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {
  getStoredToken,
  setStoredToken,
  removeStoredToken,
  getStoredRefreshToken,
  setStoredRefreshToken,
  removeStoredRefreshToken,
  getStoredCompany,
  setStoredCompany,
  removeStoredCompany,
} from "../LocalStorageManager/LocalStorageManager";
import { enqueueSnackbar } from "notistack";

const ActionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};

function authReducer(state, action) {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      console.log("[AuthProvider] Login successful:", action.payload.company);
      return {
        ...state,
        company: action.payload.company,
        loading: false,
        error: null,
      };
    case ActionTypes.LOGOUT:
      console.log("[AuthProvider] Logged out.");
      return { ...initialState, loading: false };
    case ActionTypes.SET_LOADING:
      console.log("[AuthProvider] Loading state:", action.payload);
      return { ...state, loading: action.payload };
    case ActionTypes.SET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };

    case ActionTypes.SET_ERROR:
      console.error("[AuthProvider] Error:", action.payload);
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

const initialState = {
  company: getStoredCompany(),
  loading: true,
  error: null,
  enqueueSnackbar,
};
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

    const clearAuthData = useCallback(() => {
      console.log("[AuthProvider] Clearing authentication data.");
      removeStoredToken();
      removeStoredRefreshToken();
      removeStoredCompany();
      setIsOnline(false);
      dispatch({ type: ActionTypes.LOGOUT });
    }, []);

    const initializeAuth = useCallback(async () => {
      console.log("[AuthProvider] Initializing authentication...");
      dispatch({ type: ActionTypes.SET_LOADING, payload: true });

      const token = getStoredToken();
      const refreshToken = getStoredRefreshToken();
      const company = getStoredCompany();

      if (!token || !refreshToken || !company) {
        console.warn(
          "[AuthProvider] Missing token or company data. Logging out..."
        );
        clearAuthData();
        return;
      }

      try {
        const response = await axiosInstance.get("/auth/validate-token", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.data.valid || !response.data.company) {
          throw new Error("Invalid or expired token.");
        }

        const { company: validatedCompany } = response.data;
        setIsOnline(validatedCompany.isOnline);
        dispatch({
          type: ActionTypes.LOGIN_SUCCESS,
          payload: { company: validatedCompany },
        });
      } catch (error) {
        console.warn(
          "[AuthProvider] Token validation failed. Attempting refresh..."
        );

        try {
          const refreshResponse = await axiosInstance.post("/auth/token", {
            refreshToken,
          });

          const { accessToken } = refreshResponse.data;
          setStoredToken(accessToken);

          const refreshedCompany = { ...company, isOnline: true };
          setIsOnline(refreshedCompany.isOnline);
          dispatch({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: { company: refreshedCompany },
          });
        } catch (refreshError) {
          console.error(
            "[AuthProvider] Token refresh failed:",
            refreshError.message
          );
          clearAuthData();
          navigate("/login", { replace: true });
        }
      } finally {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
      }
    }, [clearAuthData, navigate]);

    useEffect(() => {
      initializeAuth();
    }, [initializeAuth]);

const handleLogin = async (formData) => {
  console.log("[AuthProvider] Attempting login with data:", formData);

  try {
    const response = await axiosInstance.post("/auth/login", formData);
    console.log("[handleLogin] Login response received:", response.data);

    const { accessToken, refreshToken, company } = response.data;

    if (!accessToken || !refreshToken || !company) {
      throw new Error("Incomplete login response. Missing required fields.");
    }

    // Validate trucks and shipments
    if (!Array.isArray(company.trucks)) {
      console.warn("Trucks data is not an array. Defaulting to empty array.");
      company.trucks = [];
    }
    if (!Array.isArray(company.shipments)) {
      console.warn(
        "Shipments data is not an array. Defaulting to empty array."
      );
      company.shipments = [];
    }

    setStoredToken(accessToken);
    setStoredRefreshToken(refreshToken);
    setStoredCompany(company);

    console.log("[handleLogin] Backend returned isOnline:", company.isOnline);
    setIsOnline(company.isOnline);

    dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: { company } });

    navigate("/loadboard");
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || "Login failed. Please try again.";
    console.error("[handleLogin] Login error:", errorMessage);

    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: errorMessage,
    });
  }
};



  const handleLogout = useCallback(async () => {
    console.log("[AuthProvider] Logging out.");
    try {
      await axiosInstance.post("/auth/logout");

      clearAuthData();
      dispatch({ type: ActionTypes.LOGOUT });
      navigate("/login", { replace: true });

      window.location.reload();
    } catch (error) {
      console.error("Error during logout:", error.message);
      clearAuthData();
      dispatch({ type: ActionTypes.LOGOUT });
      navigate("/login", { replace: true });
      window.location.reload();
    }
  }, [clearAuthData, dispatch, navigate]);

  const handleCompanyRegister = async (formData) => {
    console.log("[AuthProvider] Attempting registration with data:", formData);
    dispatch({ type: ActionTypes.SET_LOADING, payload: true });
    try {
      const response = await axiosInstance.post("/auth/register", formData);
      const { accessToken, refreshToken, company } = response.data;
      setStoredToken(accessToken);
      setStoredRefreshToken(refreshToken);
      setStoredCompany(company);
      console.log(
        "[AuthProvider] Registration successful for company:",
        company
      );
      dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: { company } });
      navigate("/loadboard");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Registration failed. Please try again.";
      console.error("[AuthProvider] Registration error:", errorMessage);
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: errorMessage,
      });
    } finally {
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    }
  };

  const fetchAllShipments = useCallback(async () => {
    try {
      console.log("[CLIENT] Fetching all shipments...");
      const response = await axiosInstance.get("/shipment/all");
      if (response?.data?.success && Array.isArray(response.data.shipments)) {
        console.log(
          `[CLIENT] Successfully fetched ${response.data.shipments.length} shipments.`
        );
        const processedShipments = response.data.shipments.map((shipment) => ({
          shipmentId: shipment.shipmentId,
          ...shipment,
          _id: shipment.shipmentId,
          shipper: shipment.shipper || {},
          deliveryCompany: shipment.deliveryCompany || {},
          assignedDriver: shipment.assignedDriver || {
            fullName: "Not Assigned",
            email: "Not Assigned",
          },
          shipmentQuantity: Number(shipment.shipmentQuantity) || 0,
        }));
        console.log("[CLIENT] Processed Shipments:", processedShipments);
        return processedShipments;
      }
      console.warn("[CLIENT] No shipments found.");
      return [];
    } catch (error) {
      console.error("[CLIENT] Error fetching shipments:", error.message);
      return [];
    }
  }, []);

  const fetchCompanyDetails = useCallback(async (companyId) => {
    if (!companyId || typeof companyId !== "string") {
      console.warn("[CLIENT] Invalid companyId provided:", companyId);
      return null;
    }
    try {
      console.log(`[CLIENT] Fetching details for company ID: ${companyId}`);
      const { data } = await axiosInstance.get(
        `/company/companies/${companyId}`
      );
      if (data?.success) {
        console.log(
          "[CLIENT] Company details fetched successfully:",
          data.company
        );
        return data.company;
      } else {
        console.error("[CLIENT] Failed to fetch company details:", data.error);
        return null;
      }
    } catch (error) {
      console.error(
        `[CLIENT] Error fetching company details for ID ${companyId}:`,
        error.message
      );
      return null;
    }
  }, []);


const fetchResources = useCallback(async () => {
  if (!state.company?.id) {
    enqueueSnackbar("Company ID is missing. Unable to fetch resources.", {
      variant: "warning",
    });
    return null;
  }

  setLoading(true);

  try {
    const { data } = await axiosInstance.get(
      `/company/${state.company.id}/available-resources`
    );

    if (!data?.success || !data.availableResources) {
      enqueueSnackbar("No resources found. Please try again later.", {
        variant: "error",
      });
      return null;
    }

    const {
      allTrucks = [],
      allDrivers = [],
      shipmentsByStatus = { available: [], inTransit: [], completed: [] },
      companyType = "",
      totalRevenueOrExpense = 0,
      revenueBreakdown = {},
    } = data.availableResources;

    console.log("[CLIENT] Total revenue/expense:", totalRevenueOrExpense);
    console.log("[CLIENT] Revenue breakdown:", revenueBreakdown);

    dispatch({
      type: ActionTypes.SET_RESOURCES,
      payload: {
        allTrucks,
        allDrivers,
        shipmentsByStatus,
        totalRevenueOrExpense,
        revenueBreakdown,
        companyType,
      },
    });

    return {
      allTrucks,
      allDrivers,
      shipmentsByStatus,
      totalRevenueOrExpense,
      revenueBreakdown,
      companyType,
    };
  } catch (error) {
    console.error("[CLIENT] Error fetching resources:", error);
    enqueueSnackbar(
      `Failed to load resources. ${
        error?.response?.data?.error || "Please try again."
      }`,
      {
        variant: "error",
      }
    );
    return null;
  } finally {
    setLoading(false);
  }
}, [state.company?.id, dispatch, enqueueSnackbar]);



  
  const fetchTestimonials = async () => {
    try {
      const response = await axiosInstance.get("/testimony");
      if (response.data && response.data.testimonials) {
        console.log(
          "[fetchTestimonials] Testimonials fetched:",
          response.data.testimonials
        );
        return response.data.testimonials;
      }
      console.warn("[fetchTestimonials] No testimonials found in response.");
      return [];
    } catch (error) {
      console.error(
        "[fetchTestimonials] Error fetching testimonials:",
        error.message
      );
      throw error;
    }
  };

  const handleCompleteShipment = useCallback(
    async (shipment) => {
      try {
        const shipmentId = shipment?._id || shipment?.shipmentId;
        if (!shipmentId) {
          console.warn("[CLIENT] Missing shipment ID:", shipment);
          return { success: false };
        }
        console.log("[CLIENT] Completing shipment:", shipmentId);
        const response = await axiosInstance.patch(
          `/company/${shipmentId}/complete`
        );
        if (response.data?.success) {
          enqueueSnackbar("Shipment completed successfully!", {
            variant: "success",
          });
          return response.data;
        } else {
          throw new Error(response.data?.error || "Completion failed");
        }
      } catch (error) {
        console.error("[CLIENT] Error completing shipment:", error.message);
        enqueueSnackbar("Failed to complete shipment. Please try again.", {
          variant: "error",
        });
        return { success: false, error: error.message };
      }
    },
    [enqueueSnackbar]
  );

  
const cancelShipment = useCallback(
  async (shipmentId) => {
    if (!shipmentId) {
      console.warn("[cancelShipment] Missing shipment ID.");
      enqueueSnackbar("Shipment ID is required to cancel the shipment.", {
        variant: "warning",
      });
      return { success: false };
    }

    try {
      console.log(
        `[cancelShipment] Attempting to cancel shipment: ${shipmentId}`
      );
      const { data } = await axiosInstance.patch(
        `/company/cancel/${state.company.id}`,
        {
          shipmentId,
        }
      );

      if (data?.success) {
        enqueueSnackbar("Shipment cancelled successfully.", {
          variant: "success",
        });
        await fetchResources(); // Refresh resources to reflect updated shipment status
        return { success: true };
      }

      throw new Error(data?.error || "Cancellation failed.");
    } catch (error) {
      console.error(
        "[cancelShipment] Error cancelling shipment:",
        error.message
      );
      enqueueSnackbar(`Error cancelling shipment: ${error.message}`, {
        variant: "error",
      });
      return { success: false, error: error.message };
    }
  },
  [state.company?.id, fetchResources, enqueueSnackbar]
);



  return (
    <AuthContext.Provider
      value={{
        company: state.company,
        isAuthenticated: !!state.company,
        loading: state.loading,
        error: state.error,
        isOnline,
        handleLogin,
        handleLogout,
        fetchCompanyDetails,
        fetchResources,
        handleCompleteShipment,
        handleCompanyRegister,
        fetchAllShipments,
        fetchTestimonials,
        cancelShipment,
      }}
    >
      {state.loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => useContext(AuthContext);









// const handleDeleteShipment = useCallback(
//   async (shipmentId) => {
//     if (!shipmentId) {
//       enqueueSnackbar("Invalid shipment ID.", { variant: "warning" });
//       return false;
//     }

//     try {
//       const { data } = await axiosInstance.delete(`/company/${shipmentId}`);
//       if (data?.success) {
//         enqueueSnackbar("Shipment deleted successfully.", {
//           variant: "success",
//         });
//         return true;
//       }
//       enqueueSnackbar(data?.error || "Failed to delete shipment.", {
//         variant: "error",
//       });
//     } catch (error) {
//       enqueueSnackbar("Error deleting shipment.", { variant: "error" });
//     }
//     return false;
//   },
//   [enqueueSnackbar]
// );

