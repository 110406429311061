import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faStar,
  faTruck,
  faBox,
} from "@fortawesome/free-solid-svg-icons";

// Styled Components
const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
`;

const CompanyName = styled.h6`
  font-size: 1.2rem;
  margin: 12px 0;
  font-weight: 500;
`;

const Card = styled(motion.div)`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const LogoWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  border: 2px solid #ddd;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StatusChip = styled.div`
  margin: 8px auto;
  padding: 6px 12px;
  font-size: 0.85rem;
  border-radius: 16px;
  color: #fff;
  background: ${(props) => (props.online ? "#28a745" : "#6c757d")};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 6px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const Stat = styled.div`
  text-align: center;

  .fa-icon {
    margin-bottom: 4px;
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
    margin: 0;
  }
`;

// Component
const CompanyList = ({ companies, onSelect }) => (
  <ListContainer>
    {companies.map((company) => (
      <Card
        key={company._id}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => onSelect(company)}
      >
        {/* Logo */}
        <LogoWrapper>
          <img
            src={company.logoUrl || "/default-logo.png"}
            alt={`${company.companyName} Logo`}
          />
        </LogoWrapper>

        {/* Company Name */}
        <CompanyName>{company.companyName}</CompanyName>

        {/* Status */}
        <StatusChip online={company.isOnline}>
          <FontAwesomeIcon icon={faCircle} />
          {company.isOnline ? "Online" : "Offline"}
        </StatusChip>

        {/* Statistics */}
        <StatsContainer>
          <Stat>
            <FontAwesomeIcon
              icon={faStar}
              className="fa-icon"
              style={{ color: "#fdd835" }}
            />
            <p>
              {company.reviews?.length
                ? (
                    company.reviews.reduce((sum, r) => sum + r.rating, 0) /
                    company.reviews.length
                  ).toFixed(1)
                : "No Reviews"}
            </p>
          </Stat>
          <Stat>
            <FontAwesomeIcon
              icon={faTruck}
              className="fa-icon"
              style={{ color: "#007bff" }}
            />
            <p>Trucks: {company.trucks?.length || 0}</p>
          </Stat>
          <Stat>
            <FontAwesomeIcon
              icon={faBox}
              className="fa-icon"
              style={{ color: "#4caf50" }}
            />
            <p>Shipments: {company.finishedShipmentsCount || 0}</p>
          </Stat>
        </StatsContainer>
      </Card>
    ))}
  </ListContainer>
);

// PropTypes
CompanyList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      isOnline: PropTypes.bool,
      reviews: PropTypes.arrayOf(
        PropTypes.shape({
          rating: PropTypes.number.isRequired,
        })
      ),
      trucks: PropTypes.array,
      finishedShipmentsCount: PropTypes.number,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CompanyList;
