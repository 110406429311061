import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faChartBar,
  faStar,
  faPaperPlane,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./AuthContext/AuthContext";
import axiosInstance from "./axiosInstance";
import DashboardContent from "./DashboardContent";
import AssignDriverToTruck from "./AssignDriverToTruck";
import ShipmentDetailsDialog from "./ShipmentDetailsDialog";
import CompanyInfo from "./CompanyInfo";
import RevenueChart from "./RevenueChart";
import { motion } from "framer-motion";
import './Dashboard.css'
const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: #f7f7f7;
  min-height: 100vh;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;

  h3 {
    margin-bottom: 15px;
    color: #333;
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    resize: none;
  }

  .rating-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    svg {
      cursor: pointer;
      color: #ccc;
      font-size: 1.5rem;
      transition: color 0.3s;
    }

    .selected {
      color: #f4c430;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
  }

  .submit {
    background: #007bff;
    color: white;

    &:hover {
      background: #0056b3;
    }
  }

  .close {
    background: #dc3545;
    color: white;
    margin-top: 10px;

    &:hover {
      background: #c82333;
    }
  }
`;
const RevenueModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    transform: translateY(-2px);
  }
`;

const ToggleButton = styled(Button)`
  background: ${(props) => (props.$active ? "#dc3545" : "#02226d")};
  color: #ffc107;

  &:hover {
    background: ${(props) => (props.$active ? "#c82333" : "#0056b3")};
  }
`;

const PostTestimonyButton = styled(Button)`
  background: #40dc64;
  color: #02226d;

  &:hover {
    background: #218838;
  }
`;

const RevenueButton = styled(Button)`
  background: #ffc107;
  color: #02226d;

  &:hover {
    background: #e0a800;
  }
`;

const RevenueModalContent = styled(motion.div)`
  background: white;
  width: 90%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;

  h3 {
    margin-bottom: 15px;
    color: #333;
  }

  .close {
    background: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background: #c82333;
    }
  }
`;


const Dashboard = ({ resources }) => {
  const {
    company,
    fetchAllShipments,
    handlePickupShipment,
    handleCompleteShipment,
    cancelShipment,
    handleDeleteShipment,
  } = useAuth();

  const companyId = company?.id;

  const [categorizedShipments, setCategorizedShipments] = useState({
    available: [],
    inTransit: [],
    completed: [],
  });
  const [loading, setLoading] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRevenueModalOpen, setIsRevenueModalOpen] = useState(false);
  const [testimonyText, setTestimonyText] = useState("");
  const [rating, setRating] = useState(0);

 

  const handleComplete = async (shipment) => {
    if (!shipment) return;
    try {
      const response = await handleCompleteShipment(shipment);

      if (response?.success) {
        await fetchAndCategorizeShipments();
      } else {
        throw new Error(response?.error || "Completion failed.");
      }
    } catch (error) {
      console.error("[CLIENT] Error during completion:", error.message);
    }
  };

  const handlePostTestimony = async () => {
    if (!testimonyText.trim() || rating === 0) {
      return;
    }
    try {
      const response = await axiosInstance.post("/testimony", {
        message: testimonyText,
        rating,
        companyId: company.id,
        avatar: company.logoUrl || "",
      });
      setIsModalOpen(false);
      setTestimonyText("");
      setRating(0);
    } catch (error) {
      console.error("[Dashboard] Error posting testimony:", error.message);
    }
  };

  const handleRatingClick = (starValue) => {
    setRating(starValue);
  };

  const filterAndNormalizeShipments = useCallback(
    (shipments) => {
      if (!companyId) return [];
      const uniqueShipments = new Map();
      shipments.forEach((shipment) => {
        if (
          shipment.shipper?._id === companyId ||
          shipment.deliveryCompany?._id === companyId
        ) {
          uniqueShipments.set(shipment.shipmentId, {
            ...shipment,
            shipmentQuantity: Number(shipment.shipmentQuantity || 0),
          });
        }
      });
      return Array.from(uniqueShipments.values());
    },
    [companyId]
  );

  const fetchAndCategorizeShipments = useCallback(async () => {
    if (!companyId) return;
    setLoading(true);
    try {
      const allShipments = await fetchAllShipments();
      const filteredShipments = filterAndNormalizeShipments(allShipments);
      const categorized = {
        available: filteredShipments.filter(
          (shipment) => shipment.status?.toLowerCase() === "available"
        ),
        inTransit: filteredShipments.filter(
          (shipment) => shipment.status?.toLowerCase() === "in transit"
        ),
        completed: filteredShipments.filter(
          (shipment) => shipment.status?.toLowerCase() === "completed"
        ),
      };
      setCategorizedShipments(categorized);
    } catch (error) {
      console.error("Error fetching shipments:", error.message);
    } finally {
      setLoading(false);
    }
  }, [companyId, fetchAllShipments, filterAndNormalizeShipments]);

  useEffect(() => {
    fetchAndCategorizeShipments();
  }, [fetchAndCategorizeShipments]);

  const handleToggleCompanyInfo = () => setShowCompanyInfo((prev) => !prev);


  const handlePickup = async (shipment, assignedTruckId, assignedDriverId) => {
    if (!shipment || !assignedTruckId || !assignedDriverId) {
      return;
    }
    try {
      const response = await handlePickupShipment(shipment._id, {
        assignedTruckId,
        assignedDriverId,
      });
      if (response?.success) {
        fetchAndCategorizeShipments();
      } else {
        throw new Error(response.error || "Pickup failed.");
      }
    } catch (error) {
      console.error("Error during pickup:", error.message);
    }
  };

const handleCancelShipment = async (shipmentId) => {
  if (!shipmentId) {
    console.warn("[handleCancelShipment] Missing shipment ID.");
  
    return;
  }
  try {
    console.log(
      `[handleCancelShipment] Attempting to cancel shipment: ${shipmentId}`
    );
    const response = await cancelShipment(shipmentId);
    if (response?.success) {
    
      await fetchAndCategorizeShipments();
    } else {
      throw new Error(response.error || "Cancellation failed.");
    }
  } catch (error) {
    console.error(
      "[handleCancelShipment] Error cancelling shipment:",
      error.message
    );
  }
};


  const handleOpenRevenueModal = () => setIsRevenueModalOpen(true);
  const handleCloseRevenueModal = () => setIsRevenueModalOpen(false);
return (
  <DashboardContainer>
    <div className="dashboard-buttons">
      <ToggleButton $active={showCompanyInfo} onClick={handleToggleCompanyInfo}>
        {showCompanyInfo
          ? "Hide Company Information"
          : "Show Company Information"}
      </ToggleButton>
      <PostTestimonyButton onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faPlusCircle} />
        Post Testimony
      </PostTestimonyButton>
      <RevenueButton onClick={handleOpenRevenueModal}>
        <FontAwesomeIcon icon={faChartBar} />
        View Revenue
      </RevenueButton>
    </div>
    {isModalOpen && (
      <ModalOverlay>
        <ModalContent>
          <h3>Post Your Testimony</h3>
          {company?.companyName && (
            <p>{`Posting as: ${company.companyName}`}</p>
          )}
          <textarea
            placeholder="Share your experience..."
            value={testimonyText}
            onChange={(e) => setTestimonyText(e.target.value)}
          />
          <div className="rating-container">
            {[1, 2, 3, 4, 5].map((star) => (
              <FontAwesomeIcon
                key={star}
                icon={faStar}
                className={star <= rating ? "selected" : ""}
                onClick={() => handleRatingClick(star)}
              />
            ))}
          </div>
          <button className="submit" onClick={handlePostTestimony}>
            <FontAwesomeIcon icon={faPaperPlane} /> Submit
          </button>
          <button className="close" onClick={() => setIsModalOpen(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Close
          </button>
        </ModalContent>
      </ModalOverlay>
    )}
    {isRevenueModalOpen && (
      <RevenueModalOverlay>
        <RevenueChart
          companyId={companyId}
          onClose={handleCloseRevenueModal}
        />
      </RevenueModalOverlay>
    )}

    {showCompanyInfo && company && (
      <CompanyInfo
        company={company}
        refreshResources={fetchAndCategorizeShipments}
      />
    )}

    {company?.companyType === "Delivery" &&
      resources?.allTrucks &&
      resources?.allDrivers && (
        <AssignDriverToTruck
          trucks={resources.allTrucks}
          drivers={resources.allDrivers}
        />
      )}

    <DashboardContent
      shipments={categorizedShipments}
      loading={loading}
      onView={(shipment) =>
        setSelectedShipment({
          ...shipment,
          key: shipment._id || shipment.shipmentNumber,
        })
      }
      onPickup={handlePickup}
      onDelete={handleDeleteShipment}
    />
    {selectedShipment && (
      <ShipmentDetailsDialog
        open={!!selectedShipment}
        shipment={selectedShipment}
        onClose={() => setSelectedShipment(null)}
        onPickupComplete={fetchAndCategorizeShipments}
        onCancelShipment={() => handleCancelShipment(selectedShipment._id)}
        onComplete={handleComplete}
        availableTrucks={resources?.allTrucks || []}
        availableDrivers={resources?.allDrivers || []}
      />
    )}
  </DashboardContainer>
);



};

export default Dashboard;
