import React, { useState } from "react";
import { Typography, Divider, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faBuilding,
  faEnvelope,
  faPhone,
  faDollarSign,
  faWeightHanging,
  faInfoCircle,
  faRoute,
  faSyncAlt,
  faCalendarAlt,
  faClipboardCheck,
  faListAlt,
  faBalanceScale,
  faCogs,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import './ShipmentDetailsSection.css'
const Container = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const CompanyInfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #f1f1f1;
  }
`;

const AvatarWrapper = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ddd;
`;

const OnlineStatus = styled.div`
  color: ${(props) => (props.$online ? "#4caf50" : "#b0bec5")};
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const ModalContent = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background: #007bff;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 20px;

  &:hover {
    background: #0056b3;
  }
`;

const DetailText = styled.span`
  font-weight: bold;
  ${'' /* color: #333; */}
`;

const InfoTitle = styled.div`
  font-size: 0.95rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  ${'' /* color: #555; */}

  svg {
    margin-right: 8px;
  }
`;

const ShipmentDetailsSection = ({ shipment }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  const renderCompanyInfo = (company) => (
    <>
      <AvatarWrapper src={company.logoUrl || "/default-logo.png"} />
      <Typography variant="h6" sx={{ fontWeight: 700, textAlign: "center" }}>
        {company.companyName || "Unknown"}
      </Typography>
      <OnlineStatus $online={company.isOnline}>
        <FontAwesomeIcon icon={faCircle} style={{ marginRight: 8 }} />
        {company.isOnline ? "Online" : "Offline"}
      </OnlineStatus>
      <Divider style={{ margin: "10px 0" }} />
      <Typography variant="body2">
        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: 8 }} />
        Address: {company.companyAddress || "Unknown"}
      </Typography>
      <Typography variant="body2">
        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
        Email: {company.companyEmail || "Not Provided"}
      </Typography>
      <Typography variant="body2">
        <FontAwesomeIcon icon={faPhone} style={{ marginRight: 8 }} />
        Phone: {company.phoneNumber || "Not Provided"}
      </Typography>
    </>
  );

  const renderDetails = (label, value, icon) => (
    <InfoTitle>
      <FontAwesomeIcon icon={icon} style={{ marginRight: 8 }} />
      {label}: <DetailText>{value || "N/A"}</DetailText>
    </InfoTitle>
  );

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Shipper Details
      </Typography>
      {shipment?.shipper ? (
        <CompanyInfoBox onClick={() => setSelectedCompany(shipment.shipper)}>
          <AvatarWrapper
            src={shipment.shipper.logoUrl || "/default-logo.png"}
          />
          <Typography variant="subtitle1">
            {shipment.shipper.companyName}
          </Typography>
          <OnlineStatus $online={shipment.shipper.isOnline}>
            <FontAwesomeIcon icon={faCircle} style={{ marginRight: 8 }} />
            {shipment.shipper.isOnline ? "Online" : "Offline"}
          </OnlineStatus>
        </CompanyInfoBox>
      ) : (
        <Typography>No shipper details available.</Typography>
      )}

      <Divider style={{ margin: "20px 0" }} />

      <Typography variant="h6" gutterBottom>
        Delivery Company Details
      </Typography>
      {shipment?.deliveryCompany ? (
        <CompanyInfoBox
          onClick={() => setSelectedCompany(shipment.deliveryCompany)}
        >
          <AvatarWrapper
            src={shipment.deliveryCompany.logoUrl || "/default-logo.png"}
          />
          <Typography variant="subtitle1">
            {shipment.deliveryCompany.companyName}
          </Typography>
          <OnlineStatus $online={shipment.deliveryCompany.isOnline}>
            <FontAwesomeIcon icon={faCircle} style={{ marginRight: 8 }} />
            {shipment.deliveryCompany.isOnline ? "Online" : "Offline"}
          </OnlineStatus>
        </CompanyInfoBox>
      ) : (
        <Typography>No delivery company details available.</Typography>
      )}

      <Divider style={{ margin: "20px 0" }} />

      <div>
        <div className="shipment-details">
          {renderDetails(
            "Shipment Number",
            shipment?.shipmentNumber,
            faClipboardCheck
          )}
          {renderDetails(
            "Total Miles",
            `${shipment?.totalMiles} miles`,
            faRoute
          )}
        </div>

        <div className="shipment-details">
          {renderDetails(
            "Shipment Quantity",
            shipment?.shipmentQuantity,
            faListAlt
          )}
          {renderDetails("Weight", `${shipment?.weight} lbs`, faWeightHanging)}
        </div>

        <div className="shipment-details">
          {renderDetails("Truck Type", shipment?.truckType, faTruck)}
          {renderDetails("Truck Size", `${shipment?.truckSize} ft`, faCogs)}
        </div>

        <div className="shipment-details">
          {renderDetails(
            "Total Cost",
            `$${shipment?.totalCost}`,
            faBalanceScale
          )}
          {renderDetails(
            "Cost Per Mile",
            `$${shipment?.costPerMile}`,
            faDollarSign
          )}
        </div>

        <div className="shipment-details">
          {renderDetails("Status", shipment?.status, faClipboardCheck)}
          {renderDetails(
            "Is Round Trip",
            shipment?.isRoundTrip ? "Yes" : "No",
            faSyncAlt
          )}
        </div>

        <div className="shipment-details">
          {renderDetails(
            "Pickup Date",
            shipment?.pickupTime
              ? new Date(shipment.pickupTime).toLocaleDateString()
              : "N/A",
            faCalendarAlt
          )}
          {renderDetails(
            "Drop-off Date",
            shipment?.dropOffTime
              ? new Date(shipment.dropOffTime).toLocaleDateString()
              : "N/A",
            faCalendarAlt
          )}
        </div>

        {renderDetails("Description", shipment?.description, faInfoCircle)}
      </div>

      <Modal
        open={!!selectedCompany}
        onClose={() => setSelectedCompany(null)}
        aria-labelledby="company-modal"
      >
        <ModalContent>
          {selectedCompany ? (
            <>
              {renderCompanyInfo(selectedCompany)}
              <CloseButton onClick={() => setSelectedCompany(null)}>
                Close
              </CloseButton>
            </>
          ) : (
            <Typography>Loading company details...</Typography>
          )}
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ShipmentDetailsSection;
