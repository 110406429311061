import axios from "axios";
import {
  getStoredToken,
  setStoredToken,
  getStoredRefreshToken,
  removeStoredToken,
  removeStoredRefreshToken,
  removeStoredCompany,
} from "./LocalStorageManager/LocalStorageManager";

const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3003";

const axiosInstance = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getStoredToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;

    if (response?.status === 401 && config.url !== "/auth/login") {
      const refreshToken = getStoredRefreshToken();

      if (!refreshToken) {
        console.warn("[Axios] No refresh token available. Logging out...");
        removeStoredToken();
        removeStoredRefreshToken();
        removeStoredCompany();
        window.location.href = "/login";
        return Promise.reject(error);
      }

      try {
        const refreshResponse = await axios.post(
          `${baseURL}/auth/token`,
          { refreshToken },
          { headers: { "Content-Type": "application/json" } }
        );

        const { accessToken } = refreshResponse.data;
        setStoredToken(accessToken);

        config.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstance.request(config);
      } catch (refreshError) {
        console.error("[Axios] Token refresh failed:", refreshError.message);
        removeStoredToken();
        removeStoredRefreshToken();
        removeStoredCompany();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
