import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faDollarSign,
  faGavel,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import axiosInstance from "./axiosInstance";
import ShipmentDetailsSection from "./ShipmentDetailsSection";
import { useAuth } from "./AuthContext/AuthContext";
import { useSocket } from "../context/SocketContext";
import animations from "./animations";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const DialogWrapper = styled(motion.div)`
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  max-width: 800px;
  width: 95%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const LiveNegotiationContainer = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const BidList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin: 10px 0;
  border-top: 1px solid #ddd;
  padding-top: 10px;
`;

const BidItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.$bgColor || "#007bff"};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.$hoverBg || "#0056b3"};
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  margin: 10px 0;

  &:focus {
    outline: none;
    border-color: #3f51b5;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  margin: 10px 0;

  &:focus {
    outline: none;
    border-color: #3f51b5;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`;

const SuccessMessage = styled(motion.div)`
  color: green;
  font-size: 1.2rem;
  text-align: center;
  margin: 10px 0;
`;

const FailureMessage = styled(motion.div)`
  color: red;
  font-size: 1.2rem;
  text-align: center;
  margin: 10px 0;
`;

const PickupSuccessAnimation = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
};

const PickupFailureAnimation = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
};




const ShipmentDetailsDialog = ({
  open,
  onClose,
  shipment: initialShipment,
  onPickupComplete,
  onComplete,
  onCancelShipment,
  loadingAction = false,
  availableTrucks = [],
  availableDrivers = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company, cancelShipment } = useAuth();
  const { socket } = useSocket();
  const [shipment, setShipment] = useState(initialShipment || {});
  const [bids, setBids] = useState(initialShipment?.bids || []);
  const [bidAmount, setBidAmount] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [biddingClosed, setBiddingClosed] = useState(false);
  const [currentBid, setCurrentBid] = useState(
    initialShipment?.bids?.length
      ? Math.min(...initialShipment.bids.map((bid) => bid.bidAmount))
      : 0
  );

  const [selectedTruck, setSelectedTruck] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");

    const handleCompleteShipment = async () => {
      if (!shipment) {
        console.error("Shipment data is invalid.");
        return;
      }
      await onComplete(shipment); // Pass the shipment to `handleComplete`
      onClose(); // Close the dialog after completion
    };

const handleCancelShipment = async () => {
  if (!shipment?._id) {
    console.warn(
      "[ShipmentDetailsDialog] Missing shipment ID for cancellation."
    );
    enqueueSnackbar("Shipment ID is required to cancel the shipment.", {
      variant: "warning",
    });
    return;
  }

  try {
    console.log(
      `[ShipmentDetailsDialog] Attempting to cancel shipment: ${shipment._id}`
    );
    const response = await cancelShipment(shipment._id);

    if (response?.success) {
      enqueueSnackbar("Shipment cancelled successfully.", {
        variant: "success",
      });
      onCancelShipment(); // Notify parent component to refresh data
      onClose(); // Close the dialog
    } else {
      throw new Error(response.error || "Failed to cancel shipment.");
    }
  } catch (error) {
    console.error(
      "[ShipmentDetailsDialog] Error cancelling shipment:",
      error.message
    );
    enqueueSnackbar(`Error cancelling shipment: ${error.message}`, {
      variant: "error",
    });
  }
};

  const handlePickup = async () => {
    if (!selectedTruck || !selectedDriver) {
   
      return;
    }
    try {
      const response = await axiosInstance.patch(
        `/company/pickup/${company.id}`,
        {
          shipmentId: shipment?._id,
          assignedTruckId: selectedTruck,
          assignedDriverId: selectedDriver,
        }
      );
      if (response.data?.success) {
   
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onPickupComplete();
          onClose();
        }, 1500);
      } else {
        throw new Error(response.data?.error || "Failed to confirm pickup.");
      }
    } catch (error) {
  
    }
  };

  useEffect(() => {
    if (open && shipment?._id) {
      console.log("[Socket.IO] Joining room:", shipment._id);
      socket.emit("joinRoom", { roomId: shipment._id, companyId: company.id });

      // Listen for new bids
      socket.on("newBid", (bid) => {
        console.log("[Socket.IO] New bid received:", bid);
        setBids((prevBids) => [...prevBids, bid]);
        setCurrentBid((prev) =>
          prev ? Math.min(prev, bid.bidAmount) : bid.bidAmount
        );
      });

      // Listen for bidding closed event
      socket.on("biddingClosed", (data) => {
        console.log("[Socket.IO] Bidding closed:", data);
        enqueueSnackbar(data.message, { variant: "info" });
        setBiddingClosed(true);
      });

      // Cleanup on component unmount
      return () => {
        console.log("[Socket.IO] Leaving room:", shipment._id);
        socket.emit("leaveRoom", { roomId: shipment._id });
        socket.off("newBid");
        socket.off("biddingClosed");
      };
    }
  }, [open, shipment?._id, company.id, socket, enqueueSnackbar]);

  const handleGetBid = async () => {
    if (!shipment?._id) {
      enqueueSnackbar("Error: Unable to find the shipment.", {
        variant: "error",
      });
      return;
    }

    try {
      const response = await axiosInstance.get(`/company/${shipment._id}`);
      console.log("[Debug] Updated shipment:", response.data.shipment);

      if (response.data?.success) {
        const updatedShipment = response.data.shipment;
        setBids(updatedShipment.bids || []);
        setCurrentBid(
          updatedShipment.bids?.length
            ? Math.min(...updatedShipment.bids.map((bid) => bid.bidAmount))
            : null
        );
      }
    } catch (error) {
      console.error("[Debug] Error fetching bids:", error);
      enqueueSnackbar("Failed to refresh bids.", { variant: "error" });
    }
  };



const handleSubmitBid = async (acceptCounterBid = false) => {
  if (!shipment?._id) {
    enqueueSnackbar("Error: Unable to find the shipment. Please try again.", {
      variant: "error",
    });
    return;
  }
  const bidToSubmit = acceptCounterBid
    ? shipment.counterBid // Accept the counter bid amount
    : parseFloat(bidAmount); // Use the entered bid for submission

  if (!bidToSubmit || isNaN(bidToSubmit) || bidToSubmit <= 0) {
    enqueueSnackbar("Please enter a valid bid amount greater than 0.", {
      variant: "warning",
    });
    return;
  }
  try {
    const response = await axiosInstance.post(`/company/${shipment._id}/bid`, {
      companyId: company.id,
      bidAmount: bidToSubmit,
      acceptCounterBid,
    });

    if (response.data?.success) {
      enqueueSnackbar(response.data.message, { variant: "success" });
      setShipment((prevShipment) => ({
        ...prevShipment,
        totalCost: acceptCounterBid ? bidToSubmit : prevShipment.totalCost,
        counterBid: response.data.newCounterBid, // Dynamically set the next counter bid
      }));
      setBids((prevBids) => [...prevBids, response.data.bid]); // Add the new bid to the UI
    }
  } catch (error) {
    enqueueSnackbar(error.response?.data?.message || "Failed to submit bid.", {
      variant: "error",
    });
  }
};


  const canShowLiveNegotiation =
    shipment?.status === "Available" &&
    shipment?.negotiationStatus === "Open" &&
    company?.companyType === "Delivery";

  const canPickupShipment =
    shipment?.status === "Available" && company?.companyType === "Delivery";

  const getLiveNegotiationMessage = () => {
    if (shipment?.status !== "Available") return "Shipment is not available.";
    if (company?.companyType !== "Delivery")
      return "Only delivery companies can participate in bidding.";
    return "Live Negotiation Room is available.";
  };

return open ? (
  <Overlay onClick={onClose}>
    <DialogWrapper onClick={(e) => e.stopPropagation()}>
      <Typography variant="h4" component="h2" gutterBottom>
        Shipment Details
      </Typography>

      <ShipmentDetailsSection shipment={shipment} />

      {success && (
        <SuccessMessage
          variants={PickupSuccessAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          Operation Successful!
        </SuccessMessage>
      )}

      {failure && (
        <FailureMessage
          variants={PickupFailureAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          Operation Failed!
        </FailureMessage>
      )}

      {shipment?.status === "Available" && (
        <>
          {canShowLiveNegotiation && (
            <LiveNegotiationContainer>
              <Typography variant="h6" gutterBottom>
                <FontAwesomeIcon icon={faGavel} style={{ marginRight: 8 }} />
                Live Negotiation Room
              </Typography>

              <BidList>
                {bids.length > 0 ? (
                  bids.map((bid, index) => (
                    <BidItem key={index}>
                      <Typography>
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{ marginRight: 8, color: "#007bff" }}
                        />
                        Bidder: {bid.companyName || "Unknown"}
                      </Typography>
                      <Typography>
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          style={{ marginRight: 8, color: "#28a745" }}
                        />
                        Bid Amount: ${bid.bidAmount.toFixed(2)}
                      </Typography>
                    </BidItem>
                  ))
                ) : (
                  <Typography>No bids yet. Be the first to bid!</Typography>
                )}
              </BidList>

              <Typography variant="body2" gutterBottom>
                Current Counter Bid:{" "}
                <strong>${shipment.counterBid?.toFixed(2) || "N/A"}</strong>
              </Typography>

              {shipment.counterBidder && (
                <Typography variant="body2" gutterBottom>
                  Counter Bidder:{" "}
                  <strong>
                    {shipment.counterBidder.companyName || "Unknown"}
                  </strong>
                </Typography>
              )}

              <InputField
                type="number"
                placeholder="Enter your bid amount"
                value={bidAmount}
                onChange={(e) => setBidAmount(e.target.value)}
              />

              <ButtonGroup>
                <StyledButton
                  onClick={() => handleSubmitBid(false)}
                  disabled={!bidAmount || loadingAction}
                >
                  Submit Bid
                </StyledButton>
                <StyledButton
                  $bgColor="#6c757d"
                  $hoverBg="#5a6268"
                  onClick={handleGetBid}
                >
                  Refresh Bids
                </StyledButton>
                <StyledButton
                  $bgColor="#28a745"
                  $hoverBg="#218838"
                  onClick={() => handleSubmitBid(true)}
                  disabled={
                    loadingAction ||
                    shipment.counterBid === currentBid ||
                    !shipment.counterBid
                  }
                >
                  Accept Counter Bid
                </StyledButton>
              </ButtonGroup>
            </LiveNegotiationContainer>
          )}

          {company?.companyType === "Delivery" && (
            <>
              <Typography variant="h6" gutterBottom>
                Assign Truck and Driver
              </Typography>

              <Select
                value={selectedTruck}
                onChange={(e) => setSelectedTruck(e.target.value)}
              >
                <option value="" disabled>
                  {availableTrucks.length
                    ? "Select a truck"
                    : "No trucks available"}
                </option>
                {availableTrucks.map((truck) => (
                  <option key={truck._id} value={truck._id}>
                    {truck.make} ({truck.licensePlate})
                  </option>
                ))}
              </Select>

              <Select
                value={selectedDriver}
                onChange={(e) => setSelectedDriver(e.target.value)}
              >
                <option value="" disabled>
                  {availableDrivers.length
                    ? "Select a driver"
                    : "No drivers available"}
                </option>
                {availableDrivers.map((driver) => (
                  <option key={driver._id} value={driver._id}>
                    {driver.fullName}
                  </option>
                ))}
              </Select>

              {canPickupShipment && (
                <ButtonGroup>
                  <StyledButton
                    $bgColor="#28a745"
                    $hoverBg="#218838"
                    onClick={handlePickup}
                    disabled={
                      !selectedTruck || !selectedDriver || loadingAction
                    }
                  >
                    Confirm Pickup
                  </StyledButton>
                </ButtonGroup>
              )}
            </>
          )}
        </>
      )}

      {shipment?.status === "In Transit" && (
        <ButtonGroup>
          <StyledButton
            onClick={handleCompleteShipment}
            $bgColor="#007bff"
            $hoverBg="#0056b3"
            disabled={loadingAction}
          >
            Complete Shipment
          </StyledButton>
          <StyledButton
            onClick={handleCancelShipment}
            $bgColor="#f44336"
            $hoverBg="#d32f2f"
            disabled={loadingAction}
          >
            Cancel Shipment
          </StyledButton>
        </ButtonGroup>
      )}

      {shipment?.status === "Completed" && (
        <Typography variant="body1" align="center">
          This shipment has been completed. No further actions can be taken.
        </Typography>
      )}

      <ButtonGroup>
        <StyledButton onClick={onClose} disabled={loadingAction}>
          Close
        </StyledButton>
      </ButtonGroup>
    </DialogWrapper>
  </Overlay>
) : null;


};


ShipmentDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shipment: PropTypes.object.isRequired,
  onPickupComplete: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  onCancelShipment: PropTypes.func.isRequired,
  onBidComplete: PropTypes.func.isRequired,
  loadingAction: PropTypes.bool,
  availableTrucks: PropTypes.array,
  availableDrivers: PropTypes.array,
};

export default ShipmentDetailsDialog;
