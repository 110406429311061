import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../Logo-2.png";
import "./HeaderNavbar.css";
import Nav from "./Nav";

const HeaderNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="navbar">
          <Link to="/" className="logo">
            <img src={logo} alt="Logo" width="160" />
          </Link>
          <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
            <Nav closeMenu={() => setMenuOpen(false)} />
          </nav>
          <div className="mobile-menu-icon" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size="lg" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNavbar;
