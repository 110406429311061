const TOKEN_KEY = "app_token";
const COMPANY_KEY = "app_company";
const REFRESH_TOKEN_KEY = "app_refreshToken";

const storage = {
  set(key, value) {
    try {
      const serializedValue =
        typeof value === "object" ? JSON.stringify(value) : value;
      localStorage.setItem(key, serializedValue);
      console.log(`[Storage] Set key: ${key}`, { value });
    } catch (error) {
      console.error(`[Storage] Error setting key: ${key}`, error);
    }
  },
  get(key) {
    try {
      const value = localStorage.getItem(key);
      if (!value) {
        console.warn(`[Storage] No value found for key: ${key}`);
        return null;
      }

      const parsedValue = key === COMPANY_KEY ? JSON.parse(value) : value; // Only parse JSON for COMPANY_KEY
      console.log(`[Storage] Retrieved key: ${key}`, { parsedValue });
      return parsedValue;
    } catch (error) {
      console.error(`[Storage] Error getting key: ${key}`, error);
      return null;
    }
  },
  remove(key) {
    try {
      localStorage.removeItem(key);
      console.log(`[Storage] Removed key: ${key}`);
    } catch (error) {
      console.error(`[Storage] Error removing key: ${key}`, error);
    }
  },
};

export const setStoredToken = (token) => {
  if (typeof token !== "string") {
    console.warn("[setStoredToken] Invalid token type. Expected string.");
    return;
  }
  storage.set(TOKEN_KEY, token);
};
export const getStoredToken = () => storage.get(TOKEN_KEY);
export const removeStoredToken = () => storage.remove(TOKEN_KEY);

export const setStoredCompany = (company) => {
  if (typeof company !== "object" || company === null) {
    console.warn("[setStoredCompany] Invalid company type. Expected object.");
    return;
  }
  storage.set(COMPANY_KEY, company);
};
export const getStoredCompany = () => storage.get(COMPANY_KEY);
export const removeStoredCompany = () => storage.remove(COMPANY_KEY);

export const setStoredRefreshToken = (refreshToken) => {
  if (typeof refreshToken !== "string") {
    console.warn(
      "[setStoredRefreshToken] Invalid refreshToken type. Expected string."
    );
    return;
  }
  storage.set(REFRESH_TOKEN_KEY, refreshToken);
};
export const getStoredRefreshToken = () => storage.get(REFRESH_TOKEN_KEY);
export const removeStoredRefreshToken = () => storage.remove(REFRESH_TOKEN_KEY);

export const clearAllStoredAuthData = () => {
  console.log("[Storage] Clearing all stored authentication data.");
  removeStoredToken();
  removeStoredRefreshToken();
  removeStoredCompany();
};
