export default async function fetchAddressDetails(address) {
  if (!address) {
    console.warn("[Client] Address parameter is missing.");
    return null;
  }

  const serverUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:3003";

  try {
    console.log(`[Client] Fetching geocode details for address: "${address}"`);

    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 10000);

    const response = await fetch(
      `${serverUrl}/auth/geocode?address=${encodeURIComponent(address)}`,
      { signal: controller.signal }
    );

    clearTimeout(timeout);

    if (!response.ok) {
      console.error(
        "[Client] Error fetching geocode details:",
        response.statusText
      );
      return null;
    }

    const data = await response.json();
    console.log("[Client] Geocode data received successfully.");
    return data || null;
  } catch (error) {
    if (error.name === "AbortError") {
      console.error("[Client] Request timed out.");
    } else {
      console.error("[Client] Error fetching geocode details:", error.message);
    }
    return null;
  }
}
console.log("[Client] REACT_APP_SERVER_URL:", process.env.REACT_APP_SERVER_URL);
