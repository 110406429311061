import React, { useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ShipmentTable from "./ShipmentTable";

const SectionContainer = styled.div`
  background: linear-gradient(145deg, #ffffff, #f9f9f9);
  padding: 15px 0px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
`;

const SectionHeader = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  background: #ffffff;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 10px;
  }
`;

const ShipmentCount = styled.span`
  background-color: #02226d;
  color: #ffc107;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const EmptyMessage = styled.p`
  color: #999;
  font-size: 0.95rem;
  text-align: center;
  font-style: italic;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #ddd;

  @media (max-width: 768px) {
    font-size: 0.85rem;
    padding: 15px;
  }
`;

const DashboardContent = ({ shipments = {}, onView, onDelete }) => {
  const normalizeShipments = useCallback((shipmentList) => {
    return shipmentList.map((shipment) => ({
      ...shipment,
      shipmentQuantity: Number(shipment.shipmentQuantity || 0),
    }));
  }, []);

  const renderTableOrMessage = useCallback(
    (shipmentList, message) => {
      const normalizedList = normalizeShipments(shipmentList);

      if (!normalizedList || normalizedList.length === 0) {
        return <EmptyMessage>{message}</EmptyMessage>;
      }

      return (
        <ShipmentTable
          shipments={normalizedList}
          onView={onView}
          onDelete={onDelete}
        />
      );
    },
    [normalizeShipments, onView, onDelete]
  );

  return (
    <>
      <SectionContainer>
        <SectionHeader>
          Available Shipments
          <ShipmentCount>{shipments.available?.length || 0}</ShipmentCount>
        </SectionHeader>
        {renderTableOrMessage(
          shipments.available || [],
          "No available shipments at the moment. Check back soon."
        )}
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>
          In Transit Shipments
          <ShipmentCount>{shipments.inTransit?.length || 0}</ShipmentCount>
        </SectionHeader>
        {renderTableOrMessage(
          shipments.inTransit || [],
          "No shipments are currently in transit."
        )}
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>
          Completed Shipments
          <ShipmentCount>{shipments.completed?.length || 0}</ShipmentCount>
        </SectionHeader>
        {renderTableOrMessage(
          shipments.completed || [],
          "No completed shipments yet."
        )}
      </SectionContainer>
    </>
  );
};

DashboardContent.propTypes = {
  shipments: PropTypes.shape({
    available: PropTypes.array,
    inTransit: PropTypes.array,
    completed: PropTypes.array,
  }),
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DashboardContent;
