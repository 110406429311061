import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

useEffect(() => {
  const newSocket = io(
    process.env.REACT_APP_SERVER_URL || "http://localhost:3003",
    {
      transports: ["websocket"],
      withCredentials: true,
    }
  );

  newSocket.on("connect", () => {
    console.log("[Socket.IO] Connected:", newSocket.id);
    setSocket(newSocket);
  });

  newSocket.on("disconnect", () => {
    console.log("[Socket.IO] Disconnected");
  });

  return () => {
    newSocket.disconnect();
  };
}, []);


  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
