import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Clients from "../components/Clients";
import Contact from "../components/LandingContactForm";
import Hero from "../components/Hero";
import HomeAbout from "../components/HomeAbout";
import HomeServices from "../components/HomeServices";
import HeroVideo from "../components/Images/pexels_videos_3409 (1080p).mp4";
import Testimony from "../components/Testimony";
import "./Home.css";
import { useAuth } from "../components/AuthContext/AuthContext";

const images = [
  "https://images.unsplash.com/photo-1592805144716-feeccccef5ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  "https://images.unsplash.com/photo-1561702469-c4239ced3f47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
];

const heroVideoData = [
  {
    video: HeroVideo,
    header: "Why Choose Us?",
    subHeader: "Transforming the Logistics Landscape",
    paragraph:
      "With our in-house load board, shippers and carriers can now sign up to post and pick up shipments directly—no brokers needed! Enjoy live bidding for better prices and same-day delivery across California for pickups by 8 AM. Transparency, efficiency, and innovation are at the core of our service.",
  },
  {
    image: images[0],
    header: "Direct Shipping Made Easy",
    subHeader: "No Brokers, Just Results",
    paragraph:
      "Our load board connects you directly to carriers and shippers, eliminating unnecessary fees and delays. Experience logistics made simple.",
  },
  {
    image: images[1],
    header: "Live Bidding Platform",
    subHeader: "Get the Best Deals",
    paragraph:
      "Our real-time bidding system ensures competitive pricing for every shipment. Post, bid, and ship—all in one place.",
  },
];

// Animation variants
const sectionVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

const Home = () => {
  const [testimonials, setTestimonials] = useState([]);
  const { fetchTestimonials } = useAuth();

  useEffect(() => {
    const getTestimonials = async () => {
      try {
        const fetchedTestimonials = await fetchTestimonials();
        setTestimonials(fetchedTestimonials);
      } catch (error) {
        console.error("[Home] Error fetching testimonials:", error.message);
      }
    };

    getTestimonials();
  }, [fetchTestimonials]);

  return (
    <div>
      <Hero heroData={heroVideoData} />
      <motion.div
        className="home-content"
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <motion.section variants={sectionVariants}>
          <HomeAbout />
        </motion.section>
        <motion.section variants={sectionVariants}>
          <HomeServices />
        </motion.section>
        <motion.section variants={sectionVariants}>
          <Clients />
        </motion.section>
        <motion.section variants={sectionVariants}>
          <Testimony testimonials={testimonials} />
        </motion.section>
        <motion.section variants={sectionVariants}>
          <Contact />
        </motion.section>
      </motion.div>
    </div>
  );
};

export default Home;
