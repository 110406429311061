import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useAuth } from "../components/AuthContext/AuthContext";
import ShipmentComponent from "../components/ShipmentForm/ShipmentComponent";
import axios from "axios";
import "./Loadboard.css";
import styled from "styled-components";
import axiosInstance from "../components/axiosInstance";
import Testimony from '../components/Testimony'
const LoadBoardContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const WelcomeMessage = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const ShipmentContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const UnauthenticatedMessage = styled.div`
  text-align: center;
  margin-top: 50px;
  ${'' /* color: #fff; */}

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 1rem;

   &:hover {
      background: #0056b3;
    }
  }
`;
const LoadBoard = () => {
  const { isAuthenticated, company } = useAuth();
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(company);

useEffect(() => {
  const fetchTestimonials = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/testimony/");
      console.log("Fetched Testimonials:", response.data?.testimonials);
      setTestimonials(response.data?.testimonials || []);
    } catch (error) {
      console.error("Error fetching testimonials:", error.message);
    } finally {
      setLoading(false);
    }
  };

  fetchTestimonials();
}, []);



  const renderUnauthenticated = () => (
    <div className="loadboard-unauthenticated">
      <motion.header
        className="loadboard-hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="loadboard-hero-overlay">
          <h1 className="loadboard-hero-title">
            Right Load. Right Time. Anywhere.
          </h1>
          <p className="loadboard-hero-subtitle">
            Join our platform and get the most relevant matches for your
            business – the right load for the right truck at the right price.
          </p>
          <UnauthenticatedMessage>
            <button
              className="btn primary"
              onClick={() => (window.location.href = "/login")}
            >
              Login
            </button>
            <button
              className="btn secondary"
              onClick={() => (window.location.href = "/register")}
            >
              Register
            </button>
          </UnauthenticatedMessage>
        </div>
      </motion.header>

      <main>
        <motion.section
          className="loardboard-entity-title"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="entity-main-title">
            Grow Your Business for the Long Haul
          </h1>
          <p className="entity-subtitle">
            Get the most relevant matches for your business – the right load for
            the right truck at the right price, no matter where you are.
          </p>
        </motion.section>
        <motion.section
          className="features-section"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {[
            {
              image:
                "https://plus.unsplash.com/premium_photo-1681487855134-d6c0434f91f8",
              title: "GET STARTED",
            },
            {
              image:
                "https://images.unsplash.com/photo-1713947504519-755547cf930e",
              title: "GET STARTED",
            },
          ].map((feature, index) => (
            <div className="feature-card" key={index}>
              <div className="feature-image-wrapper">
                <img
                  src={feature.image}
                  alt={`Feature ${index + 1}`}
                  className="feature-image"
                />
                <button
                  className="overlay-button"
                  onClick={() => (window.location.href = "/register")}
                >
                  {feature.title}
                </button>
              </div>
            </div>
          ))}
        </motion.section>

        <motion.section
          className="details-section-carrier"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="details-wrapper">
            <h2>The First Choice for Carriers</h2>
            {[
              {
                title: "Carrier's Choice",
                description: "The fastest freight-finder with instant refresh.",
              },
              {
                title: "Revenue Boosters",
                description: "Find higher-paying routes with the TriHaul tool.",
              },
              {
                title: "Trusted Partners",
                description: "More loads than any other load board.",
              },
              {
                title: "Accurate Insights",
                description: "See the most current truckload rates.",
              },
            ].map((item, index) => (
              <div key={index} className="details-item">
                <FontAwesomeIcon icon={faCheck} className="icon" />
                <div>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <img
            src="https://plus.unsplash.com/premium_photo-1661409343443-bf261207e133"
            alt="Carrier Options"
            className="details-image"
          />
        </motion.section>
        <motion.section
          className="details-section"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <img
            src="https://plus.unsplash.com/premium_photo-1664695368767-c42483a0bda1"
            alt="Shipper Options"
            className="details-image"
          />
          <div className="details-wrapper">
            <h2>Shipper Central</h2>
            {[
              {
                title: "Find Flexibility",
                description:
                  "Navigate market volatility with access to the largest source of capacity.",
              },
              {
                title: "Fortify Your Routing Guides",
                description:
                  "Find carriers on your most problematic lanes with the unique LaneMakers tool.",
              },
              {
                title: "Anticipate and Plan",
                description:
                  "See current rates and market conditions affecting your supply chain.",
              },
            ].map((item, index) => (
              <div key={index} className="details-item">
                <FontAwesomeIcon icon={faCheck} className="icon" />
                <div>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </motion.section>
        <motion.section
          className="membership-plans-section"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2>Membership Plans</h2>
          <div className="membership-plans-wrapper">
            {membershipPlans.map((plan, index) => (
              <div key={index} className="membership-plan-card">
                <h3>{plan.name}</h3>
                <p>{plan.description}</p>
                <p className="plan-price">${plan.price}/month</p>
                <ul className="plan-features">
                  {plan.features.map((feature, i) => (
                    <li key={i}>
                      <FontAwesomeIcon icon={faCheck} /> {feature}
                    </li>
                  ))}
                </ul>
                <button
                  className="btn primary"
                  onClick={() =>
                    (window.location.href = `/register?plan=${plan.id}`)
                  }
                >
                  Choose Plan
                </button>
              </div>
            ))}
          </div>
        </motion.section>
        <motion.section
          className="testimonials-section"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="testimonials-wrapper">
            {loading ? (
              <motion.div
                className="loading-placeholder"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                Loading testimonials...
              </motion.div>
            ) : (
              <Testimony testimonials={testimonials} />
            )}
          </div>
        </motion.section>
      </main>
    </div>
  );

  return isAuthenticated ? (
    <LoadBoardContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <WelcomeMessage>
        Welcome Back, {company?.companyName || "Valued User"}!
      </WelcomeMessage>
      <ShipmentContainer>
        <ShipmentComponent />
      </ShipmentContainer>
    </LoadBoardContainer>
  ) : (
    renderUnauthenticated()
  );
};

export default LoadBoard;