import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import { CircularProgress, Typography, Container } from "@mui/material";
import { useAuth } from "../AuthContext/AuthContext";
import CompanyList from "./CompanyList";
import CompanyModal from "./CompanyModal";
import styled from "styled-components";

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { company: reviewerCompany } = useAuth();

  const reviewerCompanyId = reviewerCompany?._id || reviewerCompany?.id;

  const fetchCompanies = async () => {
    if (!reviewerCompanyId) return;

    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/shipment/companies/oppositeCompanies/${reviewerCompanyId}`
      );
      setCompanies(response?.data?.companies || []);
    } catch (error) {
      enqueueSnackbar("Failed to load companies. Please try again later.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReviewSubmit = async (reviewData) => {
    try {
      const { companyId, rating, comment } = reviewData;
      const payload = { companyId, rating, comment };
      const response = await axiosInstance.post(
        `/company/${reviewerCompanyId}/reviews`,
        payload
      );

      setCompanies((prevCompanies) =>
        prevCompanies.map((company) =>
          company._id === companyId
            ? {
                ...company,
                reviews: [...(company.reviews || []), response.data.review],
              }
            : company
        )
      );

      enqueueSnackbar("Review submitted successfully!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error submitting review.", { variant: "error" });
    }
  };

  const fetchReviews = async (companyId) => {
    try {
      const response = await axiosInstance.get(`/company/${companyId}/reviews`);
      return response.data.reviews || [];
    } catch {
      enqueueSnackbar("Failed to load reviews.", { variant: "error" });
      return [];
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [reviewerCompanyId]);

  return (
    <Container maxWidth="lg">
      <SectionTitle>
        {reviewerCompany?.companyType === "Delivery"
          ? "Available Shipping Companies"
          : "Available Delivery Companies"}
      </SectionTitle>

      {loading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : companies.length === 0 ? (
        <Typography align="center" color="textSecondary">
          No companies found. Please check back later.
        </Typography>
      ) : (
        <CompanyList
          companies={companies}
          onSelect={(company) => setSelectedCompany(company)}
        />
      )}

      {selectedCompany && (
        <CompanyModal
          company={selectedCompany}
          onClose={() => setSelectedCompany(null)}
          onSubmitReview={handleReviewSubmit}
          fetchReviews={fetchReviews}
        />
      )}
    </Container>
  );
};

export default Companies;
