import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowRight,
  faArrowsAltH,
  faTruck,
  faRulerHorizontal,
  faCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useAuth } from "./AuthContext/AuthContext";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const StyledTableContainer = styled(TableContainer)`
  margin-top: 20px;
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;

const MobileShipmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileShipmentCard = styled(motion.div)`
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ShipperInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ddd;
`;

const DirectionIcon = styled(FontAwesomeIcon)`
  color: #007bff;
  margin: 0px 8px;
`;

const StatusIcon = styled(FontAwesomeIcon)`
  color: ${(props) => (props.$online ? "#4caf50" : "#b0bec5")};
  margin-left: 8px;
`;

const ActionButton = styled(motion.button)`
  background: ${(props) => props.bg || "#007bff"};
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.hover || "#0056b3"};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ShipmentTable = React.memo(({ shipments, onView, onDelete }) => {
  const { company } = useAuth();

  if (!shipments || shipments.length === 0) {
    return (
      <Typography variant="h6" align="center">
        No shipments available.
      </Typography>
    );
  }

  const renderShipperInfo = (shipper) => (
    <ShipperInfo>
      <Logo src={shipper?.logoUrl || "/default-logo.png"} alt="Company Logo" />
      <div>
        <Typography variant="subtitle1">
          {shipper?.companyName || "Unknown Shipper"}
        </Typography>
        <StatusIcon
          icon={faCircle}
          $online={shipper?.isOnline}
          title={shipper?.isOnline ? "Online" : "Offline"}
        />
      </div>
    </ShipperInfo>
  );

  const isDeleteAllowed = (shipment) =>
    shipment?.shipper?._id === company?.id && shipment?.status === "Available";

  return (
    <>
      {/* Desktop View */}
      <StyledTableContainer
        component={motion.div}
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shipper</TableCell>
              <TableCell>Route</TableCell>
              <TableCell>Truck Type & Size</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
              {onDelete && <TableCell>Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments.map((shipment, index) => (
              <TableRow key={`${shipment._id}-${index}`}>
                <TableCell>{renderShipperInfo(shipment.shipper)}</TableCell>
                <TableCell>
                  {shipment.origin?.city || "Unknown City"},{" "}
                  {shipment.origin?.state || "Unknown State"}
                  {shipment.isRoundTrip ? (
                    <DirectionIcon icon={faArrowsAltH} title="Round Trip" />
                  ) : (
                    <DirectionIcon icon={faArrowRight} title="One Way" />
                  )}
                  {shipment.destination?.city || "Unknown City"},{" "}
                  {shipment.destination?.state || "Unknown State"}
                </TableCell>
                <TableCell>
                  <FontAwesomeIcon icon={faTruck} style={{ marginRight: 5 }} />
                  {shipment.truckType || "N/A"}
                  <FontAwesomeIcon
                    icon={faRulerHorizontal}
                    style={{ margin: "0 5px" }}
                  />
                  {shipment.truckSize || "N/A"} ft
                </TableCell>
                <TableCell>${shipment.totalCost || "N/A"}</TableCell>
                <TableCell>{shipment.shipmentQuantity || "N/A"}</TableCell>
                <TableCell>{shipment.status || "Unknown"}</TableCell>
                <TableCell>
                  <ActionButton
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => onView(shipment)}
                    aria-label="View Shipment"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ActionButton>
                </TableCell>
                {isDeleteAllowed(shipment) && (
                  <TableCell>
                    <ActionButton
                      as={motion.button}
                      bg="#dc3545"
                      hover="#c82333"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => onDelete(shipment._id)}
                      aria-label="Delete Shipment"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </ActionButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Mobile View */}
      <MobileShipmentList>
        {shipments.map((shipment, index) => (
          <MobileShipmentCard
            key={`${shipment._id}-${index}`}
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            {renderShipperInfo(shipment.shipper)}
            <Typography variant="body2">
              <strong>Route:</strong> {shipment.origin?.city || "Unknown City"},{" "}
              {shipment.origin?.state || "Unknown State"}
              {shipment.isRoundTrip ? (
                <FontAwesomeIcon icon={faArrowsAltH} title="Round Trip" />
              ) : (
                <FontAwesomeIcon icon={faArrowRight} title="One Way" />
              )}
              {shipment.destination?.city || "Unknown City"},{" "}
              {shipment.destination?.state || "Unknown State"}
            </Typography>
            <Typography variant="body2">
              <strong>Truck:</strong> <FontAwesomeIcon icon={faTruck} />{" "}
              {shipment.truckType || "N/A"}, {shipment.truckSize || "N/A"} ft
            </Typography>
            <Typography variant="body2">
              <strong>Total Cost:</strong> ${shipment.totalCost || "N/A"}
            </Typography>
            <Typography variant="body2">
              <strong>Status:</strong> {shipment.status || "Unknown"}
            </Typography>
            <div style={{ display: "flex", gap: 8 }}>
              <ActionButton
                as={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => onView(shipment)}
                aria-label="View Shipment"
              >
                <FontAwesomeIcon icon={faEye} />
              </ActionButton>
              {isDeleteAllowed(shipment) && (
                <ActionButton
                  as={motion.button}
                  bg="#dc3545"
                  hover="#c82333"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => onDelete(shipment._id)}
                  aria-label="Delete Shipment"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ActionButton>
              )}
            </div>
          </MobileShipmentCard>
        ))}
      </MobileShipmentList>
    </>
  );
});

ShipmentTable.propTypes = {
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      shipper: PropTypes.shape({
        _id: PropTypes.string,
        companyName: PropTypes.string,
        isOnline: PropTypes.bool,
        logoUrl: PropTypes.string,
      }),
      origin: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
      destination: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
      truckType: PropTypes.string,
      truckSize: PropTypes.number,
      totalCost: PropTypes.number,
      shipmentQuantity: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ShipmentTable;
