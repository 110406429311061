import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import Header from "../../pages/Header";

const LoginContainer = styled(Box)(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.paper,
  textAlign: "center",
  marginBottom:80,
  marginTop: 80,
}));

const LoginTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.background.default,
  },
  marginBottom: theme.spacing(2),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  height: 48,
  fontSize: "1rem",
  fontWeight: "bold",
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}));

const Login = () => {
  const { handleLogin } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await handleLogin(formData);
      enqueueSnackbar("Login successful!", { variant: "success" });
    } catch (error) {
      console.error("Error during login:", error);
      enqueueSnackbar(
        error.response?.data?.error || "Failed to log in. Please try again.",
        { variant: "error" }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsLoading(false); // Cleanup if component is unmounted during loading
    };
  }, []);

  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      <Header title="Login" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ type: "spring", duration: 0.5 }}
      >
        <LoginContainer>
          <LoginTitle>Login</LoginTitle>
          <form onSubmit={handleFormSubmit}>
            <StyledTextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required
            />
            <StyledTextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required
            />
            <SubmitButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Login"
              )}
            </SubmitButton>
          </form>
        </LoginContainer>
      </motion.div>
    </Box>
  );
};

export default Login;
