import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axiosInstance from "./axiosInstance";
import { useSnackbar } from "notistack";

const ToggleContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 25px;

    &::before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: #28a745;
  }

  input:checked + span::before {
    transform: translateX(25px);
  }
`;

const ToggleOnlineStatus = ({
  isOnline,
  companyId,
  setCompany,
  refreshResources,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleToggleOnlineStatus = async () => {
    try {
      const updatedStatus = !isOnline;

      // Optimistic UI update
      setCompany((prev) => ({
        ...prev,
        isOnline: updatedStatus,
      }));

      const response = await axiosInstance.put(
        `/company/updateStatus/${companyId}`,
        { isOnline: updatedStatus }
      );

      if (response?.data?.success) {
        enqueueSnackbar(
          `Company is now ${updatedStatus ? "Online" : "Offline"}.`,
          { variant: "success" }
        );
        refreshResources(); // Optionally refresh data after successful update
      } else {
        throw new Error("Failed to update online status on the server.");
      }
    } catch (error) {
      console.error("Error updating online status:", error.message);

      // Revert UI changes on failure
      setCompany((prev) => ({
        ...prev,
        isOnline: !prev.isOnline,
      }));

      enqueueSnackbar("Failed to update online status. Please try again.", {
        variant: "error",
      });
    }
  };


  return (
    <ToggleContainer>
      <ToggleSwitch>
        <input
          type="checkbox"
          checked={isOnline}
          onChange={handleToggleOnlineStatus}
        />
        <span />
      </ToggleSwitch>
      <label>{isOnline ? "Online" : "Offline"}</label>
    </ToggleContainer>
  );
};

ToggleOnlineStatus.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  setCompany: PropTypes.func.isRequired,
  refreshResources: PropTypes.func.isRequired,
};

export default ToggleOnlineStatus;
