import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axiosInstance from "./axiosInstance";
import TruckInfo from "./TruckInfo";
import DriverInfo from "./DriverInfo";
import CompanyInfoForm from "./CompanyInfoForm";
import GeneralInfo from "./GeneralInfo";
import ToggleOnlineStatus from "./ToggleOnlineStatus";

const CompanyInfoContainer = styled.div`
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 20px;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  color: #007bff;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const Button = styled.button`
  background: ${(props) => props.color || "#007bff"};
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const CompanyInfo = ({ company, refreshResources }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [editedCompany, setEditedCompany] = useState(null);
  const [trucks, setTrucks] = useState([]);
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    if (company) {
      const { trucks = [], drivers = [], ...restCompany } = company;
      setEditedCompany({ ...restCompany });
      setTrucks(trucks);
      setDrivers(drivers);
    }
  }, [company]);

  const handleSave = async () => {
    try {
      await axiosInstance.put(
        `/company/updateCompany/${company.id}`,
        editedCompany
      );
      enqueueSnackbar("Company information updated successfully.", {
        variant: "success",
      });
      setIsEditing(false);
      refreshResources();
    } catch (error) {
      enqueueSnackbar("Failed to update company information.", {
        variant: "error",
      });
    }
  };

  const handleCancel = () => {
    if (company) {
      const { trucks = [], drivers = [], ...restCompany } = company;
      setEditedCompany({ ...restCompany });
      setTrucks(trucks);
      setDrivers(drivers);
    }
    setIsEditing(false);
  };

  return (
    <CompanyInfoContainer>
      <InfoHeader>
        <Title>Company Information</Title>
        {!isEditing && <Button onClick={() => setIsEditing(true)}>Edit</Button>}
      </InfoHeader>

      {isEditing ? (
        <CompanyInfoForm
          companyInfo={editedCompany}
          setCompanyInfo={setEditedCompany}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <GeneralInfo company={editedCompany} />
          <ToggleOnlineStatus
            isOnline={editedCompany?.isOnline}
            companyId={company.id}
            setCompany={setEditedCompany}
            refreshResources={refreshResources}
          />
        </>
      )}

      {editedCompany?.companyType === "Delivery" && (
        <>
          <TruckInfo
            trucks={trucks}
            setTrucks={setTrucks}
            companyId={company.id}
            refreshResources={refreshResources}
            readOnly={!isEditing}
          />
          <DriverInfo
            drivers={drivers}
            setDrivers={setDrivers}
            companyId={company.id}
            refreshResources={refreshResources}
            readOnly={!isEditing}
          />
        </>
      )}
    </CompanyInfoContainer>
  );
};

CompanyInfo.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    trucks: PropTypes.array,
    drivers: PropTypes.array,
    username: PropTypes.string,
    companyName: PropTypes.string,
    companyEmail: PropTypes.string,
    companyAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
    isOnline: PropTypes.bool,
    companyType: PropTypes.string,
  }).isRequired,
  refreshResources: PropTypes.func.isRequired,
};

export default CompanyInfo;
