import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faPhone,
  faKey,
  faIdCard,
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Header from "../../pages/Header";
import { useAuth } from "../AuthContext/AuthContext";

const RegistrationContainer = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: "40px auto",
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.paper,
}));

const RegistrationTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.8rem",
  fontWeight: "bold",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.background.default,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5),
  fontSize: "1rem",
  fontWeight: "bold",
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SuccessMessage = styled(motion.div)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.success.main,
  marginTop: theme.spacing(2),
  fontSize: "1.2rem",
}));

const ErrorMessage = styled(motion.div)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.error.main,
  marginTop: theme.spacing(2),
  fontSize: "1.2rem",
}));

const RegistrationForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleCompanyRegister } = useAuth();
  const navigate = useNavigate();

  const initialState = {
    companyName: "",
    username: "",
    password: "",
    confirmPassword: "",
    companyAddress: "",
    companyEmail: "",
    phoneNumber: "",
    companyType: "",
    dotOrMcNumber: "",
    registrationType: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.companyName || !formData.username || !formData.password) {
      enqueueSnackbar("All fields are required.", { variant: "error" });
      setError("All fields are required.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      enqueueSnackbar("Passwords do not match.", { variant: "error" });
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    setSuccess(false);
    setError("");

    try {
      await handleCompanyRegister(formData);
      setSuccess(true);
      enqueueSnackbar("Registration successful!", { variant: "success" });
      setTimeout(() => navigate("/loadboard"), 2000); // Smooth transition
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Registration failed. Please try again.";
      setError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Header title="Company Registration" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ type: "spring", duration: 0.5 }}
      >
        <RegistrationContainer>
          <RegistrationTitle>
            <FontAwesomeIcon icon={faBuilding} style={{ marginRight: 8 }} />
            Company Registration
          </RegistrationTitle>
          <form onSubmit={handleSubmit}>
            <StyledTextField
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{ marginRight: 8 }}
                  />
                ),
              }}
              required
            />
            <StyledTextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon icon={faIdCard} style={{ marginRight: 8 }} />
                ),
              }}
              required
            />
            <StyledTextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon icon={faKey} style={{ marginRight: 8 }} />
                ),
              }}
              required
            />
            <StyledTextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon icon={faKey} style={{ marginRight: 8 }} />
                ),
              }}
              required
            />
            <StyledTextField
              label="Company Address"
              name="companyAddress"
              value={formData.companyAddress}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{ marginRight: 8 }}
                  />
                ),
              }}
              required
            />
            <StyledTextField
              label="Company Email"
              name="companyEmail"
              value={formData.companyEmail}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ marginRight: 8 }}
                  />
                ),
              }}
              required
            />
            <StyledTextField
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon icon={faPhone} style={{ marginRight: 8 }} />
                ),
              }}
              required
            />
            <StyledTextField
              label="Company Type"
              name="companyType"
              select
              value={formData.companyType}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            >
              <MenuItem value="">Select Company Type</MenuItem>
              <MenuItem value="Shipping">Shipping</MenuItem>
              <MenuItem value="Delivery">Delivery</MenuItem>
            </StyledTextField>
            <StyledTextField
              label="DOT or MC Number"
              name="dotOrMcNumber"
              value={formData.dotOrMcNumber}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon icon={faIdCard} style={{ marginRight: 8 }} />
                ),
              }}
              required
            />
            <StyledTextField
              label="Registration Type"
              name="registrationType"
              select
              value={formData.registrationType}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            >
              <MenuItem value="">Select Registration Type</MenuItem>
              <MenuItem value="DOT">DOT</MenuItem>
              <MenuItem value="MC">MC</MenuItem>
            </StyledTextField>
            <SubmitButton type="submit" disabled={loading}>
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Register"
              )}
            </SubmitButton>
          </form>
          {success && (
            <SuccessMessage
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ marginRight: 8 }}
              />
              Registration Successful! Redirecting...
            </SuccessMessage>
          )}
          {error && (
            <ErrorMessage
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ marginRight: 8 }}
              />
              {error}
            </ErrorMessage>
          )}
        </RegistrationContainer>
      </motion.div>
    </Box>
  );
};

export default RegistrationForm;
