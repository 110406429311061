import React from "react";
import styled from "styled-components";

const InfoSection = styled.div`
  margin-top: 20px;

  h4 {
    color: #007bff;
    font-weight: bold;
  }

  div {
    margin-bottom: 10px;

    strong {
      margin-right: 5px;
    }

    span {
      color: #555;
    }
  }
`;

const GeneralInfo = ({ company }) => (
  <InfoSection>
    <h4>General Information</h4>
    <div>
      <strong>Company Name:</strong>
      <span>{company?.companyName || "N/A"}</span>
    </div>
    <div>
      <strong>Username:</strong>
      <span>{company?.username || "N/A"}</span>
    </div>
    <div>
      <strong>Email:</strong>
      <span>{company?.companyEmail || "N/A"}</span>
    </div>
    <div>
      <strong>Address:</strong>
      <span>{company?.companyAddress || "N/A"}</span>
    </div>
    <div>
      <strong>Phone:</strong>
      <span>{company?.phoneNumber || "N/A"}</span>
    </div>
    <div>
      <strong>Company Type:</strong>
      <span>{company?.companyType || "N/A"}</span>
    </div>
  </InfoSection>
);

export default GeneralInfo;
