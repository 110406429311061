import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faExclamationTriangle,
  faMapMarkerAlt,
  faDollarSign,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../axiosInstance";

// Styled Components
const MatchContainer = styled.div`
  padding: 20px;
  background: linear-gradient(to bottom right, #f9fafc, #eef2f9);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  max-width: 1000px;
  margin: 20px auto;
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
`;

const ShipmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ShipmentCard = styled(motion.div)`
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`;

const ShipmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ShipmentTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
`;

const ShipmentDetails = styled.div`
  font-size: 1rem;
  color: #555;
  margin-top: 10px;

  span {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    svg {
      margin-right: 8px;
      color: #007bff;
    }
  }
`;

const TruckList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
`;

const TruckItem = styled(motion.li)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #e0e0e0;

  &:first-child {
    border-top: none;
  }
`;

const TruckDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1rem;
  color: #333;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  strong {
    font-weight: bold;
    color: #007bff;
  }

  span {
    display: block;
    color: #666;
  }
`;

const AssignButton = styled(motion.button)`
  background-color: ${(props) => (props.disabled ? "#cccccc" : "#007bff")};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => !props.disabled && "#0056b3"};
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: #007bff;
  margin: 30px 0;
`;

const ErrorContainer = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: #d9534f;
  margin: 30px 0;

  button {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background: #0056b3;
    }

    &:disabled {
      background: #cccccc;
      cursor: not-allowed;
    }
  }
`;

// Framer Motion Animation Variants
const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const MatchingShipments = ({ companyId }) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assigningIds, setAssigningIds] = useState(new Set());

  const fetchMatches = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await axiosInstance.get(
        `/truck/${companyId}/match-shipments`
      );

      if (Array.isArray(data?.matches)) {
        setMatches(
          data.matches.map((match) => ({
            shipment: match.shipment,
            matchedCompanies: match.matchedCompanies || [],
          }))
        );
      } else {
        setError(data?.message || "No shipments found.");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to fetch shipments.");
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      fetchMatches();
    }
  }, [companyId, fetchMatches]);

const handleAssignShipment = async (shipmentId, truckId) => {
  console.log(`[DEBUG] Assigning Truck ${truckId} to Shipment ${shipmentId}`);
  setAssigningIds((prev) => new Set(prev).add(truckId));

  try {
    console.log(`[DEBUG] Sending PATCH request to assign-shipment.`);
    const response = await axiosInstance.patch(
      `/truck/${companyId}/assign-shipment`,
      { shipmentId, truckId }
    );

    console.log(`[DEBUG] Response received:`, response.data);
    const { shipment, truck } = response.data;

    console.log(`[DEBUG] Updating local state.`);
    setMatches((prev) =>
      prev.map((match) =>
        match.shipment.shipmentId === shipmentId
          ? {
              ...match,
              shipment: { ...match.shipment, status: shipment.status },
              matchedCompanies: match.matchedCompanies.map((company) => ({
                ...company,
                matchedTrucks: company.matchedTrucks.filter(
                  (t) => t.truckId !== truck.truckId
                ),
              })),
            }
          : match
      )
    );
  } catch (err) {
    console.error(
      `[ERROR] Failed to assign shipment:`,
      err.response?.data || err.message
    );
    alert("Failed to assign shipment.");
  } finally {
    console.log(`[DEBUG] Removing Truck ${truckId} from assigning state.`);
    setAssigningIds((prev) => {
      const updated = new Set(prev);
      updated.delete(truckId);
      return updated;
    });
  }
};


  if (loading) {
    return (
      <LoadingContainer>
        <FontAwesomeIcon icon={faCircleNotch} spin /> Loading shipments...
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
        <button onClick={fetchMatches}>Retry</button>
      </ErrorContainer>
    );
  }

  return (
    <MatchContainer>
      <Header>Matching Shipments</Header>
      <ShipmentList>
        {matches.map(({ shipment, matchedCompanies }) => (
          <ShipmentCard
            key={shipment.shipmentId}
            initial="hidden"
            animate="visible"
            variants={cardVariants}
          >
            <ShipmentDetails>
              <span>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Origin:{" "}
                {shipment.origin.city}, {shipment.origin.state}
              </span>
              <span>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Destination:{" "}
                {shipment.destination.city}, {shipment.destination.state}
              </span>
              <span>
                <FontAwesomeIcon icon={faDollarSign} /> Cost: $
                {shipment.totalCost.toFixed(2)}
              </span>
              <span>
                <FontAwesomeIcon icon={faDollarSign} /> Description: 
                {shipment.description}
              </span>

            </ShipmentDetails>
            <TruckList>
              {matchedCompanies.length > 0 ? (
                matchedCompanies.flatMap((company) =>
                  company.matchedTrucks.map((truck) => (
                    <TruckItem key={truck.truckId} whileHover={{ scale: 1.05 }}>
                      <TruckDetails>
                        <img src={company.logoUrl} alt={company.companyName} />
                        <div>
                          <strong>{company.companyName}</strong>
                          <span>Make: {truck.make}</span>
                          <span>
                            {truck.truckType} ({truck.truckSize} ft) |{" "}
                            {truck.weightLimit} lbs
                          </span>
                        </div>
                      </TruckDetails>
                      <AssignButton
                        disabled={assigningIds.has(truck.truckId)}
                        onClick={() =>
                          handleAssignShipment(
                            shipment.shipmentId,
                            truck.truckId
                          )
                        }
                        whileHover={{ scale: 1.1 }}
                      >
                        {assigningIds.has(truck.truckId)
                          ? "Assigning..."
                          : "Assign"}
                      </AssignButton>
                    </TruckItem>
                  ))
                )
              ) : (
                <TruckItem>
                  <TruckDetails>
                    No available trucks for this shipment.
                  </TruckDetails>
                </TruckItem>
              )}
            </TruckList>
          </ShipmentCard>
        ))}
      </ShipmentList>
    </MatchContainer>
  );
};

MatchingShipments.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default MatchingShipments;
