import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import PostShipment from "../PostShipment";
import ViewShipments from "../ViewShipments";
import Dashboard from "../Dashboard";
import Companies from "../ShippingCompany/Companies";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import PostTruckForm from "../PostTruckForm/PostTruckForm";
import MatchingShipments from "../PostTruckForm/MatchingShipments";

const TabContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const TabHeader = styled.div`
  display: flex;
  background-color: #02226d;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const TabButton = styled.button`
  flex: 1;
  padding: 12px 15px;
  font-size: 1rem;
  color: ${(props) => (props.active ? "#ffffff" : "#ffc107")};
  background: ${(props) => (props.active ? "#0056b3" : "transparent")};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background: ${(props) => (props.active ? "#003d80" : "#d1e9ff")};
    color: ${(props) => (props.active ? "#ffffff" : "#007bff")};
  }
  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const ContentContainer = styled(motion.div)`
  padding: 20px 0px;
  min-height: 300px;
  background: #f9f9f9;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #007bff;
`;

const ShipmentComponent = () => {
  const { fetchResources, company } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [localResources, setLocalResources] = useState({
    companyId: company?.id || "",
    allTrucks: [],
    allDrivers: [],
  });
  const [loading, setLoading] = useState(false);

  const fetchAllResources = useCallback(async () => {
    if (!company?.id) {
      enqueueSnackbar("Company information is missing.", { variant: "error" });
      return;
    }

    try {
      setLoading(true);
      const resources = await fetchResources();
      setLocalResources({
        companyId: company.id,
        allTrucks: resources.allTrucks || [],
        allDrivers: resources.allDrivers || [],
      });
    } catch (error) {
      enqueueSnackbar("Failed to load resources.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [company?.id, fetchResources, enqueueSnackbar]);

  useEffect(() => {
    fetchAllResources();
  }, [fetchAllResources]);

  const tabs =
    company?.companyType === "Delivery"
      ? [
          {
            label: "View Shipments",
            component: (
              <ViewShipments
                localResources={localResources}
                fetchAllResources={fetchAllResources}
              />
            ),
          },
          {
            label: "Post Trucks",
            component: (
              <PostTruckForm
                companyId={localResources.companyId}
                localResources={localResources}
                onSuccess={() => {
                  enqueueSnackbar("Truck posted successfully!", {
                    variant: "success",
                  });
                  fetchAllResources();
                }}
              />
            ),
          },
          {
            label: "Dashboard",
            component: <Dashboard resources={localResources} />,
          },
          { label: "Companies", component: <Companies /> },
        ]
      : [
          { label: "Post Shipment", component: <PostShipment /> },
          {
            label: "Dashboard",
            component: <Dashboard resources={localResources} />,
          },
          {
            label: "Match Shipments",
            component: (
              <MatchingShipments companyId={localResources.companyId} />
            ),
          },
          { label: "Companies", component: <Companies /> },
        ];

  return (
    <TabContainer>
      <TabHeader>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
            role="tab"
            aria-selected={activeTab === index}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabHeader>
      <AnimatePresence mode="wait">
        <ContentContainer
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : (
            tabs[activeTab]?.component
          )}
        </ContentContainer>
      </AnimatePresence>
    </TabContainer>
  );
};

export default ShipmentComponent;
