import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import "./Testimony.css";
import { useAuth } from "./AuthContext/AuthContext";

const Testimony = ({ testimonials }) => {
  // const {company} =useAuth()
  if (!testimonials.length) {
    return (
      <motion.div
        className="testimony-empty"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <p>No testimonials available at the moment.</p>
      </motion.div>
    );
  }

  return (
    <motion.section
      className="testimony-section"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="testimony-header">
        <h2>
          What Our Customers Are <span>Saying</span>
        </h2>
        <p>
          Discover how our platform has transformed businesses with seamless
          logistics and real-time solutions.
        </p>
      </div>
      <div className="testimony-cards-container">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="testimony-card"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
            <p className="testimony-text">"{testimonial.message}"</p>
            <div className="testimony-author">
              <img
                src={
                  testimonial.company?.logoUrl ||
                  "https://via.placeholder.com/80"
                }
                alt={testimonial.companyName || "Anonymous Company"}
                className="author-avatar"
              />
              <div>
                <h4>{testimonial.companyName || "Anonymous Company"}</h4>
                <div className="testimony-rating">
                  {[...Array(testimonial.rating || 5)].map((_, i) => (
                    <FontAwesomeIcon key={i} icon={faStar} className="star" />
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default Testimony;
