import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./AuthContext/AuthContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Styled Components
const RevenueModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(17, 25, 40, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const RevenueModalContent = styled(motion.div)`
  background: #1e293b;
  width: 90%;
  max-width: 900px;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  text-align: center;
  color: #e2e8f0;

  @media (max-width: 600px) {
    padding: 20px;
    width: 95%;
  }

  h3 {
    margin-bottom: 20px;
    color: #facc15;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .total-revenue {
    margin: 15px 0 25px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #38bdf8;
  }

  .close-btn {
    background: #ef4444;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
      background: #dc2626;
    }
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 400px;

  @media (max-width: 600px) {
    height: 300px;
  }
`;

const RevenueChart = ({ companyId, onClose }) => {
  const [chartData, setChartData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { fetchResources } = useAuth();

  const timeFrames = ["daily", "weekly", "monthly", "quarterly"];

  const fetchRevenueData = async () => {
    try {
      setLoading(true);
      const resources = await fetchResources();

      if (resources) {
        const { companyType, revenueBreakdown, totalRevenueOrExpense } =
          resources;

        const labels = timeFrames.map(
          (frame) => frame.charAt(0).toUpperCase() + frame.slice(1)
        );

        const datasets = [
          {
            label: companyType === "Shipping" ? "Expenses ($)" : "Revenue ($)",
            data: timeFrames.map((frame) => revenueBreakdown[frame] || 0),
            backgroundColor: [
              "rgba(63, 81, 181, 0.8)",
              "rgba(38, 166, 154, 0.8)",
              "rgba(255, 193, 7, 0.8)",
              "rgba(239, 83, 80, 0.8)",
            ],
            borderColor: "rgba(255, 255, 255, 1)",
            borderWidth: 2,
          },
        ];

        setChartData({ labels, datasets });
        setTotalAmount(totalRevenueOrExpense || 0);
      } else {
        console.warn("Resources data is unavailable.");
      }
    } catch (error) {
      console.error("[CLIENT] Error fetching revenue data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) fetchRevenueData();
  }, [companyId]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#e2e8f0",
          font: { size: 14 },
        },
      },
      title: {
        display: true,
        text: `Revenue/Expense Analysis by Time Period`,
        color: "#facc15",
        font: { size: 18 },
      },
    },
    scales: {
      x: {
        ticks: { color: "#cbd5e1" },
        title: { display: true, text: "Time Period", color: "#cbd5e1" },
      },
      y: {
        ticks: { color: "#cbd5e1" },
        title: { display: true, text: "Amount ($)", color: "#cbd5e1" },
      },
    },
  };

  return (
    <RevenueModalOverlay>
      <RevenueModalContent
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3>
          <FontAwesomeIcon icon={faChartLine} />
          {` ${
            chartData?.datasets[0]?.label.includes("Expenses")
              ? "Expenses"
              : "Revenue"
          } Overview`}
        </h3>
        {loading ? (
          <p>Loading data...</p>
        ) : chartData && totalAmount > 0 ? (
          <>
            <div className="total-revenue">
              Total {chartData?.datasets[0]?.label}: ${totalAmount.toFixed(2)}
            </div>
            <ChartContainer>
              <Bar data={chartData} options={chartOptions} />
            </ChartContainer>
          </>
        ) : (
          <p>No data available.</p>
        )}
        <button className="close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faTimesCircle} /> Close
        </button>
      </RevenueModalContent>
    </RevenueModalOverlay>
  );
};

export default RevenueChart;
