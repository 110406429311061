import React from "react";
import {
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faWeight,
  faCalendarAlt,
  faDollarSign,
  faHashtag,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ALLOWED_TRUCK_TYPES = ["Box Truck", "Flatbed", "Reefer Truck"];

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }

  .MuiInputLabel-root {
    font-size: 0.9rem;
  }
`;

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
`;

const StyledTypography = styled(Typography)`
  margin-top: 15px;
  font-size: 1rem;
  font-weight: bold;
`;

const ShipmentFormFields = ({ formState, setFormState }) => (
  <Grid container spacing={3}>
    {["origin", "destination"].map((type) => (
      <Grid item xs={12} key={type}>
        <StyledTextField
          label={`${type === "origin" ? "Origin" : "Destination"} Address`}
          variant="outlined"
          fullWidth
          value={formState[type].address}
          onChange={(e) =>
            setFormState((prev) => ({
              ...prev,
              [type]: { ...prev[type], address: e.target.value },
            }))
          }
          InputProps={{
            startAdornment: (
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: 10, color: "#757575" }}
              />
            ),
          }}
          required
        />
      </Grid>
    ))}

    <Grid item xs={6}>
      <StyledTextField
        label="Shipment Number"
        variant="outlined"
        fullWidth
        value={formState.shipmentNumber}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            shipmentNumber: e.target.value,
          }))
        }
        InputProps={{
          startAdornment: (
            <FontAwesomeIcon icon={faHashtag} style={{ marginRight: 10 }} />
          ),
        }}
        required
      />
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Shipment Quantity"
        variant="outlined"
        type="number"
        fullWidth
        value={formState.shipmentQuantity}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            shipmentQuantity: e.target.value,
          }))
        }
        InputProps={{
          startAdornment: (
            <FontAwesomeIcon icon={faBox} style={{ marginRight: 10 }} />
          ),
        }}
        required
      />
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Weight (lbs)"
        variant="outlined"
        type="number"
        fullWidth
        value={formState.weight}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            weight: e.target.value,
          }))
        }
        InputProps={{
          startAdornment: (
            <FontAwesomeIcon icon={faWeight} style={{ marginRight: 10 }} />
          ),
        }}
        required
      />
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Spending Limit"
        variant="outlined"
        fullWidth
        value={formState.spendingLimit}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            spendingLimit: e.target.value,
          }))
        }
        InputProps={{
          startAdornment: (
            <FontAwesomeIcon icon={faDollarSign} style={{ marginRight: 10 }} />
          ),
        }}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <StyledTextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        value={formState.description}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            description: e.target.value,
          }))
        }
        required
      />
    </Grid>
    <Grid item xs={12}>
      <StyledTypography>
        Total Miles: {formState.totalMiles || 0} miles, Total Cost: $
        {formState.totalCost || 0}
      </StyledTypography>
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Pickup Time"
        variant="outlined"
        type="datetime-local"
        fullWidth
        value={formState.pickupTime}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            pickupTime: e.target.value,
          }))
        }
        required
      />
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Drop-off Time"
        variant="outlined"
        type="datetime-local"
        fullWidth
        value={formState.dropOffTime}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            dropOffTime: e.target.value,
          }))
        }
        required
      />
    </Grid>
    <Grid item xs={6}>
      <FormControl fullWidth variant="outlined" required>
        <InputLabel>Truck Type</InputLabel>
        <StyledSelect
          value={formState.truckType}
          onChange={(e) =>
            setFormState((prev) => ({
              ...prev,
              truckType: e.target.value,
            }))
          }
          label="Truck Type"
        >
          {ALLOWED_TRUCK_TYPES.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Truck Size (ft)"
        variant="outlined"
        type="number"
        fullWidth
        value={formState.truckSize}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            truckSize: e.target.value,
          }))
        }
        required
      />
    </Grid>
    <Grid item xs={6}>
      <StyledTextField
        label="Departure Date"
        variant="outlined"
        type="date"
        fullWidth
        value={formState.departureDate}
        onChange={(e) =>
          setFormState((prev) => ({
            ...prev,
            departureDate: e.target.value,
          }))
        }
        required
      />
    </Grid>
    <Grid item xs={6}>
      <FormControlLabel
        control={
          <Switch
            checked={formState.isRoundTrip}
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                isRoundTrip: e.target.checked,
              }))
            }
          />
        }
        label="Round Trip"
      />
    </Grid>
  </Grid>
);

export default ShipmentFormFields;
