import React from "react";
import styled from "styled-components";

const FormContainer = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Label = styled.label`
  font-size: 0.9rem;
  margin-bottom: 8px;
  display: block;
`;

const ButtonSection = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  background: ${(props) => props.color || "#007bff"};
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.hover || "#0056b3"};
  }
`;

const LogoPreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 15px;
  display: block;
`;

const CompanyInfoForm = ({ companyInfo, setCompanyInfo, onSave, onCancel }) => (
  <FormContainer>
    <h3>Edit Company Information</h3>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <Label>Company Name</Label>
      <InputField
        type="text"
        value={companyInfo.companyName || ""}
        onChange={(e) =>
          setCompanyInfo({ ...companyInfo, companyName: e.target.value })
        }
        placeholder="Company Name"
      />
      <Label>Username</Label>
      <InputField
        type="text"
        value={companyInfo.username || ""}
        onChange={(e) =>
          setCompanyInfo({ ...companyInfo, username: e.target.value })
        }
        placeholder="Username"
      />
      <Label>Company Address</Label>
      <InputField
        type="text"
        value={companyInfo.companyAddress || ""}
        onChange={(e) =>
          setCompanyInfo({ ...companyInfo, companyAddress: e.target.value })
        }
        placeholder="Company Address"
      />
      <Label>Company Email</Label>
      <InputField
        type="email"
        value={companyInfo.companyEmail || ""}
        onChange={(e) =>
          setCompanyInfo({ ...companyInfo, companyEmail: e.target.value })
        }
        placeholder="Company Email"
      />
      <Label>Phone Number</Label>
      <InputField
        type="tel"
        value={companyInfo.phoneNumber || ""}
        onChange={(e) =>
          setCompanyInfo({ ...companyInfo, phoneNumber: e.target.value })
        }
        placeholder="Phone Number"
      />
      <Label>Logo URL</Label>
      <InputField
        type="text"
        value={companyInfo.logoUrl || ""}
        onChange={(e) =>
          setCompanyInfo({ ...companyInfo, logoUrl: e.target.value })
        }
        placeholder="Logo URL"
      />
      {companyInfo.logoUrl && (
        <LogoPreview src={companyInfo.logoUrl} alt="Company Logo" />
      )}
      <ButtonSection>
        <ActionButton type="submit">Save Changes</ActionButton>
        <ActionButton color="#dc3545" hover="#c82333" onClick={onCancel}>
          Cancel
        </ActionButton>
      </ButtonSection>
    </form>
  </FormContainer>
);

export default CompanyInfoForm;
