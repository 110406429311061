import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext/AuthContext";
import { FormWrapper, Title, StyledButton } from "./ShipmentStyles";
import axiosInstance from "./axiosInstance";
import fetchAddressDetails from "./fetchAddressDetails";
import calculateTotalMiles from "./calculateTotalMiles";
import ShipmentFormFields from "./ShipmentFormFields";
import { motion } from "framer-motion";
import "./PostShipment.css";

const initialState = {
  weight: "",
  description: "",
  spendingLimit: "",
  pickupTime: "",
  dropOffTime: "",
  totalMiles: 0,
  totalCost: 0,
  maxNegotiationPrice: "",
  origin: { address: "", street: "", city: "", state: "", zipCode: "" },
  destination: { address: "", street: "", city: "", state: "", zipCode: "" },
  isRoundTrip: false,
  departureDate: "",
  costPerMile: 3,
  truckType: "",
  truckSize: "",
  shipmentNumber: "",
  shipmentQuantity: "",
};

const parseAddressComponents = (components) => {
  const addressData = { street: "", city: "", state: "", zipCode: "" };

  components.forEach(({ types, long_name, short_name }) => {
    if (types.includes("street_number")) {
      addressData.street = `${long_name} `;
    }
    if (types.includes("route")) {
      addressData.street += long_name;
    }
    if (types.includes("locality") || types.includes("sublocality")) {
      addressData.city = long_name;
    }
    if (types.includes("administrative_area_level_1")) {
      addressData.state = short_name;
    }
    if (types.includes("postal_code")) {
      addressData.zipCode = long_name;
    }
  });

  return addressData;
};

const PostShipment = ({ onShipmentSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useAuth();
  const navigate = useNavigate();
  const [formState, setFormState] = useState(initialState);
  const [successBannerVisible, setSuccessBannerVisible] = useState(false);
  const lastFetchedAddresses = useRef({ origin: "", destination: "" });

  const handleFetchDistanceAndCost = useCallback(async () => {
    const { origin, destination, isRoundTrip } = formState;

    if (
      origin.address === lastFetchedAddresses.current.origin &&
      destination.address === lastFetchedAddresses.current.destination
    ) {
      return;
    }

    if (origin.address && destination.address) {
      try {
        const [originDetails, destinationDetails] = await Promise.all([
          fetchAddressDetails(origin.address),
          fetchAddressDetails(destination.address),
        ]);

        if (originDetails && destinationDetails) {
          const parsedOrigin = parseAddressComponents(
            originDetails.address_components
          );
          const parsedDestination = parseAddressComponents(
            destinationDetails.address_components
          );

          const totalMiles = calculateTotalMiles(
            originDetails,
            destinationDetails,
            isRoundTrip
          );

          setFormState((prev) => ({
            ...prev,
            origin: { ...prev.origin, ...parsedOrigin },
            destination: { ...prev.destination, ...parsedDestination },
            totalMiles,
            totalCost: (totalMiles * prev.costPerMile).toFixed(2),
          }));

          lastFetchedAddresses.current = {
            origin: origin.address,
            destination: destination.address,
          };
        } else {
          enqueueSnackbar("Failed to fetch address details.", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Failed to calculate distance and cost.", {
          variant: "error",
        });
      }
    }
  }, [formState, enqueueSnackbar]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handleFetchDistanceAndCost();
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [
    formState.origin.address,
    formState.destination.address,
    handleFetchDistanceAndCost,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { origin, destination } = formState;

    if (
      !origin.street ||
      !origin.city ||
      !origin.state ||
      !origin.zipCode ||
      !destination.street ||
      !destination.city ||
      !destination.state ||
      !destination.zipCode
    ) {
      enqueueSnackbar(
        "Complete address details are required for origin and destination.",
        { variant: "error" }
      );
      return;
    }

    const shipmentData = {
      ...formState,
      weight: parseFloat(formState.weight),
      companyId: company?.id,
    };
    try {
      const response = await axiosInstance.post("/shipment/new", shipmentData);
      setFormState(initialState);

      if (onShipmentSubmit) onShipmentSubmit(response.data);

      setSuccessBannerVisible(true);
      setTimeout(() => {
        setSuccessBannerVisible(false);
        navigate("/loadboard");
      }, 2000);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to post shipment. Please try again.";
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return (
    <div className="postWrapper">
      {successBannerVisible && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className="success-banner"
        >
          Shipment posted successfully!
        </motion.div>
      )}
      <FormWrapper>
        <Title variant="h5">Post Shipment</Title>
        <form onSubmit={handleSubmit}>
          <ShipmentFormFields
            formState={formState}
            setFormState={setFormState}
          />
          <StyledButton type="submit">Post Shipment</StyledButton>
        </form>
      </FormWrapper>
    </div>
  );
};

export default PostShipment;
