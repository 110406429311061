import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axiosInstance from "./axiosInstance";
import { useSnackbar } from "notistack";

const TruckContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

const TruckCard = styled.div`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #ffffff;
`;

const InputField = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#ccc")};
`;

const SelectField = styled.select`
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#ccc")};
`;

const Button = styled.button`
  background: ${(props) => props.color || "#007bff"};
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.hover || "#0056b3"};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const TruckInfo = ({
  trucks,
  setTrucks,
  refreshResources,
  companyId,
  readOnly = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingIndex, setLoadingIndex] = useState(null);

  const handleAddNewTruck = () => {
    setTrucks([
      ...trucks,
      {
        make: "",
        model: "",
        year: "",
        vin: "",
        licensePlate: "",
        mileage: 0,
        status: "Available",
        truckNumber: "",
      },
    ]);
  };

  const handleRemoveTruck = (index) => {
    setTrucks(trucks.filter((_, i) => i !== index));
  };

  const handleTruckChange = (index, field, value) => {
    setTrucks(
      trucks.map((truck, i) =>
        i === index ? { ...truck, [field]: value } : truck
      )
    );
  };

  const validateTruckFields = (truck) => {
    const requiredFields = [
      "make",
      "model",
      "year",
      "truckNumber",
      "vin",
      "licensePlate",
    ];
    for (let field of requiredFields) {
      if (!truck[field]?.trim()) {
        enqueueSnackbar(`Missing field: ${field}`, { variant: "error" });
        return false;
      }
    }
    if (!/^[A-HJ-NPR-Z0-9]{17}$/.test(truck.vin)) {
      enqueueSnackbar("Invalid VIN format (17 characters required).", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handlePostTruck = async (truck, index) => {
    if (!validateTruckFields(truck)) return;
    setLoadingIndex(index);

    try {
      const response = await axiosInstance.post(
        `/company/${companyId}/add-truck`,
        truck
      );
      enqueueSnackbar(response.data.message || "Truck added successfully!", {
        variant: "success",
      });
      refreshResources();
      handleRemoveTruck(index);
    } catch (error) {
      enqueueSnackbar(
        error.response?.data?.error || "Failed to add truck. Try again.",
        { variant: "error" }
      );
    } finally {
      setLoadingIndex(null);
    }
  };

  return (
    <TruckContainer>
      <h4>Truck Information</h4>
      {trucks.map((truck, index) => (
        <TruckCard key={index}>
          {[
            "make",
            "model",
            "year",
            "vin",
            "truckNumber",
            "licensePlate",
            "mileage",
          ].map((field) => (
            <InputField
              key={field}
              placeholder={field.toUpperCase()}
              value={truck[field] || ""}
              onChange={(e) => handleTruckChange(index, field, e.target.value)}
              disabled={readOnly}
            />
          ))}
          <SelectField
            value={truck.status}
            onChange={(e) => handleTruckChange(index, "status", e.target.value)}
            disabled={readOnly}
          >
            <option value="Available">Available</option>
            <option value="In Use">In Use</option>
            <option value="Maintenance">Maintenance</option>
          </SelectField>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              color="#28a745"
              hover="#218838"
              onClick={() => handlePostTruck(truck, index)}
              disabled={readOnly || loadingIndex === index}
            >
              {loadingIndex === index ? "Posting..." : "Post"}
            </Button>
            <Button
              color="#dc3545"
              hover="#c82333"
              onClick={() => handleRemoveTruck(index)}
              disabled={readOnly || loadingIndex === index}
            >
              Remove
            </Button>
          </div>
        </TruckCard>
      ))}
      <Button onClick={handleAddNewTruck} disabled={readOnly}>
        Add Truck
      </Button>
    </TruckContainer>
  );
};

TruckInfo.propTypes = {
  trucks: PropTypes.array.isRequired,
  setTrucks: PropTypes.func.isRequired,
  refreshResources: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default TruckInfo;
