import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faFilter,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const AssignDriverContainer = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
`;

const Header = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const FilterLabel = styled.label`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FilterSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
`;

const ScrollableDiv = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #fff;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const Th = styled.th`
  background: #02226d;
  color: #ffc107;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ffc10770;
  font-weight: 700;
`;

const StatusSpan = styled.span`
  font-weight: bold;
  color: ${({ status }) => getStatusColor(status)};
`;

const NoDataMessage = styled.div`
  text-align: center;
  color: gray;
  padding: 20px;
`;

const getStatusColor = (status) => {
  switch (status) {
    case "In Use":
      return "green";
    case "Under Maintenance":
      return "orange";
    case "Available":
      return "#02226d";
    default:
      return "gray";
  }
};

const AssignDriverToTruck = ({ trucks = [], isLoading = false }) => {
  const [filteredTrucks, setFilteredTrucks] = useState([]);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    const filtered =
      filter === "All"
        ? trucks
        : trucks.filter((truck) =>
            truck.status.toLowerCase().includes(filter.toLowerCase())
          );
    setFilteredTrucks(filtered);
  }, [filter, trucks]);

  useEffect(() => {
    console.log("[DEBUG] Trucks data passed to component:", trucks);
  }, [trucks]);

  if (isLoading) {
    return (
      <AssignDriverContainer>
        <Header>
          <FontAwesomeIcon icon={faTruck} /> Truck Assignment Status
        </Header>
        <div style={{ textAlign: "center", padding: "20px" }}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            style={{ fontSize: "1.5rem" }}
          />
        </div>
      </AssignDriverContainer>
    );
  }

  return (
    <AssignDriverContainer>
      <Header>
        <FontAwesomeIcon icon={faTruck} /> Truck Assignment Status
      </Header>

      <FilterBar>
        <FilterLabel htmlFor="truck-filter">
          <FontAwesomeIcon icon={faFilter} /> Filter Trucks:
        </FilterLabel>
        <FilterSelect
          id="truck-filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Available">Available</option>
          <option value="In Use">In Use</option>
          <option value="Under Maintenance">Under Maintenance</option>
        </FilterSelect>
      </FilterBar>

      <ScrollableDiv>
        <Table>
          <thead>
            <tr>
              <Th>Truck</Th>
              <Th>License Plate</Th>
              <Th>Driver</Th>
              <Th>Status</Th>
            </tr>
          </thead>
          <tbody>
            {filteredTrucks.length > 0 ? (
              filteredTrucks.map((truck) => (
                <tr key={truck.vin || truck.truckNumber}>
                  <Td>
                    {truck.make} {truck.model}
                  </Td>
                  <Td>{truck.licensePlate || "N/A"}</Td>
                  <Td>
                    {truck.assignedDriver
                      ? `${truck.assignedDriver.fullName} (${
                          truck.assignedDriver.phoneNumber || "No Number"
                        })`
                      : "Unassigned"}
                  </Td>

                  <Td>
                    <StatusSpan status={truck.status}>
                      {truck.status}
                    </StatusSpan>
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan="4">
                  <NoDataMessage>
                    No trucks found for the selected filter.
                  </NoDataMessage>
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollableDiv>
    </AssignDriverContainer>
  );
};

AssignDriverToTruck.propTypes = {
  trucks: PropTypes.arrayOf(
    PropTypes.shape({
      vin: PropTypes.string,
      truckNumber: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      licensePlate: PropTypes.string,
      status: PropTypes.string.isRequired,
      assignedDriver: PropTypes.shape({
        fullName: PropTypes.string,
        email: PropTypes.string,
      }),
    })
  ),
  isLoading: PropTypes.bool,
};

export default AssignDriverToTruck;
