import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axiosInstance from "../axiosInstance";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: 20px auto;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const FormTitle = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const Select = styled.select`
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const SubmitButton = styled.button`
  padding: 12px 15px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: ${(props) => (props.loading ? "#cccccc" : "#02226d")};
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.loading ? "#cccccc" : "#0056b3")};
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;

const TimeBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const AddBlockButton = styled.button`
  padding: 8px 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
  background: transparent;
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #007bff;
    color: #ffffff;
  }
`;

const PostTruckForm = ({ companyId, localResources, onSuccess }) => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [availableTrucks, setAvailableTrucks] = useState([]);
  const [timeBlocks, setTimeBlocks] = useState([{ start: null, end: null }]);
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (localResources?.allTrucks?.length > 0) {
      setAvailableTrucks(localResources.allTrucks);
    } else {
      setSubmissionError("No trucks found in local resources.");
    }
  }, [localResources]);

  const addTimeBlock = () => {
    setTimeBlocks([...timeBlocks, { start: null, end: null }]);
  };

  const handleTimeChange = (index, type, date) => {
    const updatedBlocks = [...timeBlocks];
    updatedBlocks[index][type] = date;
    setTimeBlocks(updatedBlocks);
  };

  const onSubmit = async (data) => {
    if (timeBlocks.some((block) => !block.start || !block.end)) {
      setSubmissionError(
        "All time blocks must have valid start and end times."
      );
      return;
    }

    setLoading(true);
    setSubmissionError("");
    setSuccessMessage("");

    const formattedData = {
      ...data,
      truckType: "Box Truck",
      truckSize: 26,
      timeBlocks: timeBlocks.map((block) => ({
        start: block.start?.toISOString(),
        end: block.end?.toISOString(),
      })),
    };

    try {
      const response = await axiosInstance.post(
        `/truck/${companyId}/trucks/post`,
        formattedData
      );
      console.log("[DEBUG] Truck posted successfully:", response.data);

      reset();
      setTimeBlocks([{ start: null, end: null }]);
      setSuccessMessage("Truck posted successfully!");
      onSuccess(response.data);
    } catch (error) {
      console.error("[DEBUG] Error posting truck:", error.message);
      setSubmissionError(
        error.response?.data?.error || "An unexpected error occurred."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Post Available Truck</FormTitle>
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      <form onSubmit={handleSubmit(onSubmit)} style={{display: "grid"}}>
        {submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}

        <Label htmlFor="truckId">Select Truck</Label>
        <Controller
          name="truckId"
          control={control}
          rules={{ required: "Truck is required" }}
          render={({ field }) => (
            <Select {...field}>
              <option value="" disabled>
                Select a truck
              </option>
              {availableTrucks.map((truck) => (
                <option key={truck._id} value={truck._id}>
                  {truck.truckNumber} - {truck.make} {truck.model}
                </option>
              ))}
            </Select>
          )}
        />
        {errors.truckId && (
          <ErrorMessage>{errors.truckId.message}</ErrorMessage>
        )}

        <Label htmlFor="zipCode">Zip Code</Label>
        <Input
          {...register("zipCode", { required: "Zip Code is required" })}
          type="text"
        />
        {errors.zipCode && (
          <ErrorMessage>{errors.zipCode.message}</ErrorMessage>
        )}

        <Label htmlFor="city">City</Label>
        <Input
          {...register("city", { required: "City is required" })}
          type="text"
        />
        {errors.city && <ErrorMessage>{errors.city.message}</ErrorMessage>}

        <Label htmlFor="state">State</Label>
        <Input
          {...register("state", { required: "State is required" })}
          type="text"
        />
        {errors.state && <ErrorMessage>{errors.state.message}</ErrorMessage>}

        <Label htmlFor="radius">Radius (miles)</Label>
        <Input
          {...register("radius", { required: "Radius is required" })}
          type="number"
        />
        {errors.radius && <ErrorMessage>{errors.radius.message}</ErrorMessage>}

        <Label htmlFor="weightLimit">Weight Limit (lbs)</Label>
        <Input
          {...register("weightLimit", { required: "Weight Limit is required" })}
          type="number"
        />
        {errors.weightLimit && (
          <ErrorMessage>{errors.weightLimit.message}</ErrorMessage>
        )}

        <Label htmlFor="availabilityDate">Availability Date</Label>
        <Controller
          name="availabilityDate"
          control={control}
          rules={{ required: "Availability Date is required" }}
          render={({ field }) => (
            <DatePicker
              {...field}
              selected={field.value}
              onChange={(date) => setValue("availabilityDate", date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select Date"
            />
          )}
        />
        {errors.availabilityDate && (
          <ErrorMessage>{errors.availabilityDate.message}</ErrorMessage>
        )}

        <TimeBlockContainer>
          <Label>Time Blocks</Label>
          {timeBlocks.map((block, index) => (
            <div key={index} style={{ display: "flex", gap: "10px" }}>
              <DatePicker
                selected={block.start}
                onChange={(date) => handleTimeChange(index, "start", date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                placeholderText="Start Time"
              />
              <DatePicker
                selected={block.end}
                onChange={(date) => handleTimeChange(index, "end", date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                placeholderText="End Time"
              />
            </div>
          ))}
          <AddBlockButton type="button" onClick={addTimeBlock}>
            + Add Time Block
          </AddBlockButton>
        </TimeBlockContainer>

        <SubmitButton type="submit" loading={loading} disabled={loading}>
          {loading ? "Submitting..." : "Post Truck"}
        </SubmitButton>
      </form>
    </FormContainer>
  );
};

export default PostTruckForm;
